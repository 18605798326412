import { Auth } from 'aws-amplify'

const AuthService = {
    isLoggedIn: async function() {
        try {
            await Auth.currentAuthenticatedUser()
            return true
        } catch (e) {
            console.log('not logged in ' + JSON.stringify(e))
            return false
        }
    },
    getAccessToken: async function() {
        let auth = await Auth.currentSession()
        let accessToken = auth.getAccessToken()
        let token = accessToken.getJwtToken()
        return token
    },
    getHeader: async function() {
        let token = await this.getAccessToken()
        let headers = { Authorization: 'Bearer ' + token }
        return headers
    }
}

export default AuthService
