import React, { useState } from 'react'

import Button from '@mui/material/Button'
//import Button from '@mui/material-next/Button'

import makeStyles from '@mui/styles/makeStyles'

import { useTheme } from '@mui/material/styles'

import { connect } from 'react-redux' //Code
//import { TimePicker, DatePicker } from 'antd'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
    updateShiftRequest,
    closeShiftRequestDialog,
    openShiftRequestDialog,
    resetShiftRequest,
} from '../ShiftRequest/shiftRequestSlice'

const mapDispatchToProps = (dispatch) => {
    return {
        updateShiftRequest: (state) => {
            dispatch(updateShiftRequest(state))
        },
        closeShiftRequestDialog: (state) => {
            dispatch(closeShiftRequestDialog())
        },
        openShiftRequestDialog: (state) => {
            dispatch(openShiftRequestDialog())
        },
        resetShiftRequest: (state) => {
            dispatch(resetShiftRequest())
        },
    }
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    datePicker: {
        width: '100%',
        marginTop: '0.5em',
    },
    input: {
        marginTop: '0.5em',
    },
}))

const mapStateToProps = (state) => ({
    user: state.user,
    closeShiftRequestDialog: state.closeShiftRequestDialog,
    openShiftRequestDialog: state.openShiftRequestDialog,
    shiftRequest: state.shiftRequest,
})

const ShiftRequestButton = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    /*
    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenAlert, setAlertOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    //const [isOpen, setOpen] = useState(false)
    //const [request, setRequest] = useState(new Request())

    const handleClose = () => {
        props.closeShiftRequestDialog()
    }
    */

    const handleOpen = () => {
        props.openShiftRequestDialog()
    }

    return (
        <div>
            <Button
                variant="contained"
                style={{
                    borderRadius: 28,
                    padding: '1em',
                    paddingLeft: '2em',
                    paddingRight: '2em',
                    width: !isDesktop ? '100%' : 'inherit',
                }}
                color="primary"
                onClick={handleOpen}
                size="large"
            >
                Make Nurse Request
            </Button>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftRequestButton)
