import React from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import moment from 'moment'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import userService from '../../services/userService.js'
import CircularProgress from '@mui/material/CircularProgress'
import { openSnackbarMessage } from '../SnackbarMessage/snackbarMessageSlice'

import {
    openCancelShiftDialog,
    closeCancelShiftDialog
} from './cancelShiftDialogSlice.js'

const mapStateToProps = state => ({
    isOpen: state.cancelShiftDialog.isOpen,

    shiftCollection: state.cancelShiftDialog.shiftCollection,
    shiftRequest: state.cancelShiftDialog.shiftRequest
})

const mapDispatchToProps = dispatch => {
    return {
        openDialog: () => {
            dispatch(openCancelShiftDialog())
        },

        closeDialog: () => {
            dispatch(closeCancelShiftDialog())
        },
        openSnackbarMessage: data => {
            dispatch(openSnackbarMessage(data))
        }
    }
}
const CancelShiftDialog = props => {
    const shiftRequest = props.shiftRequest

    //console.log(shift)
    const shiftCollection = props.shiftCollection
    const [isLoading, setIsLoading] = React.useState(false)
    const [cancellationFeeWarning, setCancellationFeeWarning] = React.useState(
        ''
    )

    /*
    const handleConfirmCancelNurseShiftRequest = shiftId => {
        setShiftId(shiftId)
        //setIsOpenCancelShiftDialog(true)
        props.openDialog()
        return
    }
    */
    const handleCancelShiftDialogClose = () => {
        //setIsOpenCancelShiftDialog(false)
        props.closeDialog()
    }
    const handleCancelShiftRequest = async () => {
        let headers = await AuthService.getHeader()
        setIsLoading(true)
        //console.log(shiftId)
        await axios.delete(`/api/facility/shift/${shiftRequest.shift.id}`, {
            headers: headers
        })
        //reload user data
        await userService.getAccount()
        setIsLoading(false)
        let msg = 'The nurse for this shift has been canceled.'
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success',
            autoHideDuration: 6000
        })
        props.closeDialog()
        //setIsOpenCancelShiftDialog(false)
    }

    React.useEffect(
        () => {
            let diffMins = moment(shiftCollection.start).diff(
                new Date(),
                'minutes'
            )
            //within 120 minutes
            console.log('mins from now ' + diffMins)
            let cancelWarning = ''
            if (diffMins <= 60) {
                cancelWarning = (
                    <div>
                        This shift starts in less than two hours before it
                        starts.
                        <b>
                            You will be billed for 4 hrs and a 10% penalty fee
                            for canceling the shift now.
                        </b>
                        <br />
                        <br />
                    </div>
                )
            } else if (diffMins <= 120) {
                cancelWarning = (
                    <div>
                        This shift starts in less than an hour.
                        <b>
                            You will be billed for 4 hrs and a 15% penalty fee
                            for canceling the shift now.
                        </b>
                        <br />
                        <br />
                    </div>
                )
            }
            setCancellationFeeWarning(cancelWarning)
        },
        [shiftCollection]
    )

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleCancelShiftDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Cancel Shift</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {cancellationFeeWarning}
                        Please confirm that you'd like to cancel the shift for{' '}
                        {shiftRequest.user.firstName}{' '}
                        {shiftRequest.user.lastName}, {shiftCollection.type}
                        's on {shiftCollection.date} from{' '}
                        {shiftCollection.startTime}-{shiftCollection.endTime}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelShiftDialogClose}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button
                        onClick={handleCancelShiftRequest}
                        color="primary"
                        autoFocus
                    >
                        Yes, cancel shift.
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                    <br />
                    <br />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelShiftDialog)
