import { createSlice } from '@reduxjs/toolkit'

export const shiftModalSlice = createSlice({
    name: 'shiftModal',
    initialState: false,
    reducers: {
        openModal: (state, action) => (state = true),
        closeModal: (state, action) => (state = false)
    }
})

export const open = state => state.shiftModal
export const { openModal, closeModal } = shiftModalSlice.actions

export default shiftModalSlice.reducer
