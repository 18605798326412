import axios from 'axios'
import store from '../app/store'
import { setUser } from '../features/User/userSlice.js'
import AuthService from './authService.js'
import Utils from '../helpers/utils.js'

const isShiftCollectionFilled = shiftCollection => {
    let filledShifts = shiftCollection.requests.filter(
        request => request.shift && Object.keys(request.shift).length > 0
    )

    if (shiftCollection.quantity === filledShifts.length) {
        return true
    }
    return false
}

const setSelectedFacility = (store, user, facilities) => {
    let selectedFacilityId = null
    if (store.selectedFacility && store.selectedFacility.id) {
        selectedFacilityId = store.selectedFacility.id
        let updatedFacility = facilities.find(o => o.id === selectedFacilityId)
        if (updatedFacility) {
            user.selectedFacility = updatedFacility
        }
    } else {
        // (2) TODO make smarter; ok for now bc we don't support multi-facility
        for (let x = 0; x < facilities.length; x++) {
            let facility = facilities[x]
            user.selectedFacility = facility
        }
    }
    return user
}
const UserService = {
    getAccount: async function(accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }
        //console.log(headers)
        let user = null
        let res = await axios.get('/api/facility/account/', {
            headers: headers
        })
        if (res) {
            user = res.data
        }

        console.log('getting user')
        let facilities = user.facilities

        facilities = facilities.map(facility => {
            let shiftCollections = facility.shiftCollections
            console.log('user service ')
            console.log(shiftCollections)
            shiftCollections = shiftCollections.map(shiftCollection => {
                shiftCollection = Utils.prettyFormatDateTimeRange(
                    shiftCollection
                )
                //shift.status = 'Open'
                shiftCollection.isFilled = isShiftCollectionFilled(
                    shiftCollection
                )

                // TODO move out?  add empty object
                shiftCollection.requests = shiftCollection.requests.map(
                    request => {
                        if (!request.shift) {
                            request.shift = {}
                        }
                        return request
                    }
                )
                return shiftCollection
            })
            facility.shiftCollections = shiftCollections
            return facility
        })

        // set facility if facility is not selected, update facility if exists
        // (1) replace user facility w/ refreshed facility obj
        user = setSelectedFacility(store, user, facilities)

        //console.log('user service ' + user.selectedFacility.shifts.length)

        console.log('saving user ')
        console.log(user)
        store.dispatch(setUser(user))
        console.log('end saving user')
        return user
    },
    getInvoice: async () => {
        let headers = await AuthService.getHeader()

        let invoice = null
        let res = await axios.get('/api/facility/account/invoice', {
            headers: headers
        })
        if (res) {
            invoice = res.data
        }
        return invoice
    }
}

export default UserService
