import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authData: {},
    selectedFacility: { shiftCollections: [] }
}
export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        resetUser: state => initialState,
        setUser: (state, action) => {
            //console.log('Action', action.payload)
            console.log('setting user ' + action.payload)
            state = action.payload
            return state
        }
        /*
        addComment: (state, action) => {
            console.log('Action', action)
            state = state.push({
                id: action.payload.id,
                comment: action.payload.comment,
                questionId: action.payload.questionId
            })
        },
        editComment: (state, action) => {
            state = state.map(comment => {
                if (comment.id === action.payload.id) {
                    return {
                        id: action.payload.id,
                        comment: action.payload.comment,
                        questionId: action.payload.questionId
                    }
                } else {
                    return {
                        ...comment
                    }
                }
            })
            return state
        },
        removeComment: (state, action) => {
            state = state.filter(comment => comment.id !== action.payload.id)

            return state
        }
        */
    }
})

export const { setUser, resetUser } = userSlice.actions

//export const comments = (state) => state.comments;
//export const userState = state => state.userState
export const user = state => state

export default userSlice.reducer
