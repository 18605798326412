import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

import { setUser } from '../features/User/userSlice.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import TzMoment from '../plugins/TzMoment'
import MobileBackButton from '../features/MobileBackButton/MobileBackButton'

const useStyles = makeStyles({
    table: {
        minWidth: 0,
    },
})

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
    }
}

let priorServerData = []

const Billing = (props) => {
    const [invoiceRows, setInvoiceRows] = React.useState([])

    const createRows = (rows) => {
        let tmpRows = []
        let bigTotal = 0

        const tzMoment = new TzMoment(props.user.selectedFacility.timezone)
        rows.forEach((item) => {
            let role = ''
            if (item.role) {
                role = item.role.shortName + ': '
            }

            /*
        if (hrs > 5) {
            msg += '. Includes 30 min lunch break.'
        }
        */
            //console.log(JSON.stringify(item))
            let total = item.amount ? item.amount : item.pendingAmount
            let invoice = item.invoice ? item.invoice : ''
            total = parseFloat(total).toFixed(2)
            bigTotal = (parseFloat(bigTotal) + parseFloat(total)).toFixed(2)

            let hrs = ''
            if (item.shiftCollection && item.shiftCollection.duration) {
                hrs = item.shiftCollection.duration
            }

            let rate = ''
            if (item.payRate && item.payRate.billableHourlyRate) {
                rate = item.payRate.billableHourlyRate.toFixed(2)
            }

            let invoiceDate = tzMoment.moment(item.invoiceDate).format('l')
            let isPaid = item.isPaid ? 'Paid' : 'Not Paid'
            let id = item.id
            let url = item.url

            let tmp = {
                id,
                invoice,
                hrs,
                total,
                rate,
                invoiceDate,
                isPaid,
                url,
            }
            tmpRows.push(tmp)
        })
        if (tmpRows.length === 0) {
            tmpRows = [
                {
                    id: '',
                    invoiceDate: 'No Invoices',
                    invoice: '',
                    isPaid: '',
                    url: '',
                },
            ]
        }
        return [tmpRows, bigTotal]
    }

    const pageLoad = async () => {
        let headers = await AuthService.getHeader()

        let rows = []
        if (
            props.user &&
            props.user.selectedFacility &&
            props.user.selectedFacility.id
        ) {
            let facilityId = props.user.selectedFacility.id

            let res
            try {
                res = await axios.get(
                    `/api/facility/account/invoice/facility/${facilityId}`,
                    {
                        headers: headers,
                    }
                )

                console.log(res.data)

                rows = res.data
            } catch (e) {
                window.alert(
                    'Sorry, there was an error. Please try again in a few seconds.'
                )
            }
        }

        priorServerData = rows
        //ctotal unused; dep
        let cTotal = 0
        ;[rows, cTotal] = createRows(rows)
        //console.log(JSON.stringify(rows))

        setInvoiceRows(rows)
    }

    React.useEffect(() => {
        console.log('loading billing use effect')
        let rows = []
        let cTotal = 0
        ;[rows, cTotal] = createRows(priorServerData)

        setInvoiceRows(rows)

        pageLoad()
    }, [])
    const classes = useStyles()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '1em',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <MobileBackButton topSafeArea={props.topSafeArea} />
            <h2> Invoices </h2>
            <br />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        {/*
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                Details
                            </TableCell>
                        </TableRow>
                        */}
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell align="">Status</TableCell>
                            <TableCell align="right">
                                Download Invoice
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceRows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.invoiceDate}</TableCell>
                                <TableCell>{row.invoice}</TableCell>
                                <TableCell align="">{row.isPaid}</TableCell>
                                <TableCell align="right">
                                    {row.url ? (
                                        <a
                                            href={row.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Invoice {row.invoice}
                                        </a>
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <br />
            <br />
            <br />
        </Container>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Billing)
