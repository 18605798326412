import React, { useEffect } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Rating from '@mui/material/Rating'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import CircularProgress from '@mui/material/CircularProgress'
import { openFileDialog } from '../File/fileSlice'

import { openReviewDialog, closeReviewDialog } from './reviewDialogSlice.js'

const mapStateToProps = (state) => ({
    isOpen: state.reviewDialog.isOpen,
    shift: state.reviewDialog.shift,
    //shiftStatus: state.reviewDialog.shiftStatus,
    shiftCollection: state.reviewDialog.shiftCollection,
    shiftRequest: state.reviewDialog.shiftRequest,
})

class Review {
    constructor(shift) {
        let data = shift.review

        this.id = data.id ? data.id : null
        this.review = data.review ? data.review : null
        this.rating = data.rating ? data.rating : null
        this.shiftId = shift.id ? shift.id : null
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: () => {
            dispatch(openReviewDialog())
        },

        closeDialog: () => {
            dispatch(closeReviewDialog())
        },

        openFileDialog: (resourceType, associate) => {
            dispatch(
                openFileDialog({
                    resourceType: resourceType,
                    associate: associate,
                })
            )
        },
    }
}
const ReviewDialog = (props) => {
    const shiftRequest = props.shiftRequest

    const shiftCollection = props.shiftCollection
    const shift = props.shift
    const [isLoading, setIsLoading] = React.useState(false)
    const [ratingValue, setRatingValue] = React.useState(null)
    const [reviewText, setReviewText] = React.useState('')
    //const [clockDetails, setClockDetails] = React.useState('')

    useEffect(() => {
        // load init data
        if (!props.isOpen) {
            return
        }
        async function pageLoad() {
            setReviewText('')
            setRatingValue(null)
            let headers = await AuthService.getHeader()
            let shiftId = shift.id
            setIsLoading(true)
            try {
                let res = await axios.get(
                    `/api/facility/review/shift/${shiftId}`,
                    {
                        headers: headers,
                    }
                )
                let reviewData = res.data
                if (reviewData && reviewData.review) {
                    setReviewText(reviewData.review)
                }
                if (reviewData && reviewData.rating) {
                    setRatingValue(parseInt(reviewData.rating))
                }
            } catch (e) {
                console.log(e)
                alert(
                    'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
                )
            }
            setIsLoading(false)
        }
        pageLoad()
    }, [props.isOpen])

    const handleSaveReview = async (type) => {
        setIsLoading(true)
        let data = { review: reviewText, rating: ratingValue }

        let headers = await AuthService.getHeader()
        let shiftId = shift.id
        try {
            let res = await axios.put(
                `/api/facility/review/shift/${shiftId}`,
                data,
                {
                    headers: headers,
                }
            )
            console.log('updates shig no show')
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
            setIsLoading(false)
            return
        }

        setIsLoading(false)
        handleCloseDialog()
    }

    const fullName = `${shiftRequest.user.firstName}  ${shiftRequest.user.lastName}, ${shiftCollection.type}`

    const shiftDateTime = `${shiftCollection.date} from ${shiftCollection.startTime}-
            ${shiftCollection.endTime}.`

    //console.log(props.shiftStatus)
    //console.log(props.profileDialog)
    //
    const handleCloseDialog = () => {
        props.closeDialog()
    }

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div
                    style={{
                        height: props.topSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    Review of {fullName}
                </DialogTitle>
                <DialogContent>
                    This review is for the shift taken on {shiftDateTime}
                    <br />
                    <br />
                    <div style={{ textAlign: '', fontSize: '1.5em' }}>
                        Write Review
                    </div>
                    <TextField
                        id="review"
                        multiline
                        name="review"
                        rows={4}
                        style={{ width: '100%' }}
                        placeholder=""
                        variant="filled"
                        value={reviewText}
                        onChange={(e) => {
                            setReviewText(e.target.value)
                        }}
                    />
                    <br />
                    <br />
                    <div style={{ textAlign: '', fontSize: '1.5em' }}>
                        Select Your Rating
                    </div>
                    <Rating
                        name="rating"
                        value={ratingValue}
                        required
                        onChange={(e, value) => setRatingValue(value)}
                    />
                    <DialogContentText id="alert-dialog-description" />
                </DialogContent>
                <div style={{ textAlign: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                </div>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveReview} color="primary">
                        Save Review
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: props.bottomSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDialog)
