import React from 'react'

import { withRouter } from 'react-router-dom'
import { Hub } from 'aws-amplify'
import Container from '@mui/material/Container'

//import SortFacilityMenu from '../SortFacilityMenu.js'
import JobListings from '../features/JobListings/JobListings.js'
import ShiftRequestButton from '../features/ShiftRequestButton/ShiftRequestButton'

import withStyles from '@mui/styles/withStyles'

//import Paper from '@mui/material/Paper'
import { setUser, resetUser } from '../features/User/userSlice.js'
import UserService from '../services/userService.js'

import authService from '../services/authService.js'
import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

//import store from '../app/store'
import Utils from '../helpers/utils'

const useStyles = (theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
})

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
        resetUser: () => {
            dispatch(resetUser())
        },
    }
}

const Home = (props) => {
    React.useEffect(() => {
        let userObj = null
        let refreshDataInterval = null

        Hub.listen('auth', async ({ payload }) => {
            const { event } = payload
            if (event === 'signIn' || event === 'autoSignIn') {
                console.log('auth change!')
                console.log('auth state reloading')
                //let token = authData.signInUserSession.accessToken.jwtToken
                userObj = await UserService.getAccount()
                //console.log('user obj ' + JSON.stringify(userObj))
                //this.props.setUser(userObj)
                console.log(props.user)

                if (!Utils.hasFacilities(userObj)) {
                    console.log('no facilities')
                    props.history.push('/onboarding')
                    return
                }

                Utils.loadChatUser(props.user)
                refreshDataInterval = setInterval(() => {
                    console.log('running data refresh ' + new Date())
                    UserService.getAccount()
                }, 60000)
                /*
                // REDIRECT USER TO ONBOARDING
                */

                // only show widget if fully onboarded
                Utils.showTawk()
                //console.log(userObj)
                //console.log(Utils.hasFacilities(userObj))
                console.log('end test')
            } else if (event === 'signOut') {
                clearInterval(refreshDataInterval)
                props.resetUser()

                props.history.push('/')
                Utils.hideTawk()
                console.log('signed out')
            }
        })

        const pageInit = async () => {
            let isLoggedIn = await authService.isLoggedIn()
            if (isLoggedIn) {
                console.log(' is logged in account reload, WHY!?')
                userObj = await UserService.getAccount()

                console.log('running has facilities? ')
                if (!Utils.hasFacilities(userObj)) {
                    console.log('no facilities')
                    props.history.push('/onboarding')
                }
                /*
            // REDIRECT USER TO ONBOARDING
            if (!Utils.hasFacilities(this.props.user)) {
                console.log('no facilities')
                this.props.history.push('/onboarding')
            }
            */
            }
        }
        pageInit()
    }, [])

    const { classes } = props

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                //padding: 0,
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '0',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                <h2>Looking for nurses?</h2>
                <ShiftRequestButton />
            </div>
            <JobListings />
            <br />
            <br />
            <br />
        </Container>
    )
}

//export default withStyles(useStyles) connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
export default compose(
    withStyles(useStyles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Home)
