import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import { TimePicker } from 'antd'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import UserService from '../../services/userService.js'
import CircularProgress from '@mui/material/CircularProgress'

import {
    openClockDialog,
    closeClockDialog,
    updateShift,
} from './clockDialogSlice.js'

import TzMoment from '../../plugins/TzMoment'
const moment = require('moment')

const mapStateToProps = (state) => ({
    isOpen: state.clockDialog.isOpen,
    shift: state.clockDialog.shift,
    shiftStatus: state.clockDialog.shiftStatus,
    shiftCollection: state.clockDialog.shiftCollection,
    shiftRequest: state.clockDialog.shiftRequest,
})

class ClockAction {
    constructor(type, shiftCollection, shift) {
        let tzMoment = new TzMoment(shiftCollection.timezone)
        this.type = type ? type : ''
        this.clockInTime =
            shift && shift.clockInTime
                ? tzMoment.moment(shift.clockInTime)
                : null
        this.clockOutTime =
            shift && shift.clockOutTime
                ? tzMoment.moment(shift.clockOutTime)
                : null
        this.clockInTimeNote = null
        this.clockOutTimeNote = null
        console.log('type ' + type)
        //console.log('test jasdfasdf')
        //console.log(this.time)
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: () => {
            dispatch(openClockDialog())
        },

        closeDialog: () => {
            dispatch(closeClockDialog())
        },

        updateShift: (shift) => {
            dispatch(updateShift(shift))
        },
    }
}

const ClockDialog = (props) => {
    const shiftRequest = props.shiftRequest

    const associate = shiftRequest.user

    //console.log(shift)
    const shiftCollection = props.shiftCollection

    const [clockAction, setClockAction] = React.useState(
        new ClockAction(null, shiftCollection, props.shift)
    )
    const [isNoShowDialogOpen, setIsNoShowDialogOpen] = React.useState(false)
    const [isClockInTimePickerDisabled, setIsClockInTimePickerDisabled] =
        React.useState(false)
    const [isClockOutTimePickerDisabled, setIsClockOutTimePickerDisabled] =
        React.useState(false)
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)
    const shiftDateTime = `${shiftCollection.date} from ${shiftCollection.startTime}-${shiftCollection.endTime}.`

    React.useEffect(() => {
        setClockAction(new ClockAction(null, shiftCollection, props.shift))
    }, [shiftCollection])

    React.useEffect(() => {
        let diff = moment.duration(
            moment(props.shiftCollection.start).diff(moment(new Date()))
        )
        let diffMins = diff.asMinutes()
        console.log(diffMins)
        if (diffMins > 90) {
            setIsClockInTimePickerDisabled(true)
        } else {
            setIsClockInTimePickerDisabled(false)
        }

        if (diffMins > 0) {
            setIsClockOutTimePickerDisabled(true)
        } else {
            setIsClockOutTimePickerDisabled(false)
        }
    }, [props.isOpen])

    const [isLoading, setIsLoading] = React.useState(false)
    const openClockDialog = () => {
        props.openClockDialog()
        /*
        props.openProfileDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
        */
    }
    const handleCloseClockDialog = (type) => {
        //setIsClockDialogOpen(false)
        props.closeDialog()
        // clockiinTime clockoutTime

        let shift = props.shift ? props.shift : null
        let clockA = new ClockAction(type, shiftCollection, shift)
        setClockAction(clockA)
        console.log('handle type ' + type)
        console.log('handle clock ' + clockA.time)
    }

    const handleNurseNoShowOpenConfirm = (type) => {
        let timeAfterStart = moment.duration(
            moment(new Date()).diff(moment(props.shiftCollection.start))
        )
        let minsAfterStart = timeAfterStart.asMinutes()
        console.log(minsAfterStart)
        if (minsAfterStart < 60) {
            alert(
                'You need to wait at least 60 minutes after the start of the shift before you can mark a nurse as no-show.'
            )
            return
        }

        setIsNoShowDialogOpen(true)
    }

    const handleCloseNoShowDialog = () => {
        setIsNoShowDialogOpen(false)
    }
    const handleNurseNoShow = async (type) => {
        let data = { isNoShow: true }

        setIsLoading(true)
        let headers = await AuthService.getHeader()
        try {
            let res = await axios.put(
                `/api/facility/shift/${props.shift.id}`,
                data,
                {
                    headers: headers,
                }
            )
            let shiftObj = res.data
            console.log('updates shig no show')
            console.log(shiftObj)
            //props.updateShift(shiftObj)
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
        }

        await UserService.getAccount()
        setIsNoShowDialogOpen(false)
        //handleCloseDialog()
        setIsLoading(false)
        alert(
            "We're sorry for the inconvenience caused by this nurse not showing. You will not be billed for this nurse."
        )
        handleCloseNoShowDialog()
    }

    const handleSaveTimeDialog = async () => {
        // determin if PM or am

        setIsLoading(true)
        let data = { clockInTime: null, clockOutTime: null }

        if (clockAction.clockInTime) {
            let clockTime = moment(clockAction.clockInTime)
            if (clockTime.isAfter(moment(shiftCollection.end))) {
                console.log('test')
                clockTime.subtract(1, 'days')
                clockTime = moment(clockTime).toDate()
            }
            data.clockInTime = clockTime
            data.clockInTimeNote = clockAction.clockInTimeNote
        }
        if (clockAction.clockOutTime) {
            //console.log('testing')
            //console.log(shiftCollection.start)
            //console.log(shiftCollection.startTime)
            //console.log(clockTime)

            let clockTime = moment(clockAction.clockOutTime)
            if (moment(shiftCollection.start).isAfter(clockTime)) {
                clockTime.add(1, 'days')
                clockTime = moment(clockTime).toDate()
            }
            data.clockOutTime = clockTime
            data.clockOutTimeNote = clockAction.clockOutTimeNote
            console.log('clock time ' + clockTime)
        }

        let headers = await AuthService.getHeader()
        try {
            let res = await axios.put(
                `/api/facility/shift/${props.shift.id}`,
                data,
                {
                    headers: headers,
                }
            )
            let shiftObj = res.data
            console.log('updates shig')
            console.log(shiftObj)
            props.updateShift(shiftObj)
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
        }

        //update data
        // await UserService.getAccount()
        UserService.getAccount()
        console.log('inspect clock action')
        console.log(clockAction)
        handleCloseClockDialog()

        //renderClockDetails()
        setIsLoading(false)
    }

    const clockTimeClick = (isOpen, actionType) => {
        if (!isOpen) {
            return
        }
        console.log('testing open ')

        let diff = moment.duration(
            moment(props.shiftCollection.start).diff(moment(new Date()))
        )
        let diffMins = diff.asMinutes()
        console.log(diffMins)
        if (actionType === 'clockInTime') {
            if (diffMins > 90) {
                alert(
                    'You can only check-in the nurse up to 90 mins before the shift starts.'
                )
                return
            }
        } else {
            if (diffMins > 0) {
                alert(
                    'You can only check-out the nurse after the shift starts.'
                )
            }
        }

        console.log('test clock open')
        console.log(clockAction.clockOutTime)
        console.log('end clock test')
        //let clockA = new ClockAction(null, shiftCollection, props.shift)
        if (
            actionType === 'clockOutTime' &&
            clockAction.clockOutTime === null
        ) {
            setClockAction({
                ...clockAction,
                clockOutTime: moment(shiftCollection.end),
            })
        }
        if (actionType === 'clockInTime' && clockAction.clockInTime === null) {
            setClockAction({
                ...clockAction,
                clockInTime: moment(shiftCollection.start),
            })
        }
    }
    const clockTimeChange = (actionType, time) => {
        setClockAction({ ...clockAction, [actionType]: time })
        //clockAction.time = time
        console.log('clock change event ' + actionType + ' ' + time)

        console.log('inspecing clock action')
        console.log(clockAction)
    }
    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleCloseClockDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    Clock-in/Clock-out: {associate.firstName}{' '}
                    {associate.lastName}, {shiftCollection.type}
                </DialogTitle>
                <DialogContent>
                    <b>Scheduled Shift</b>:<br />
                    {shiftDateTime}
                    <br />
                    <br />
                    <br />
                    <FormLabel
                        component="legend"
                        style={{ fontWeight: 'bolder' }}
                    >
                        Clock-in Time
                    </FormLabel>
                    <TimePicker
                        style={{ marginTop: '0.5em' }}
                        use12hours
                        format="h:mm a"
                        placeholder="Add clock-in"
                        disabled={isClockInTimePickerDisabled}
                        onClick={(isOpen) =>
                            clockTimeClick(isOpen, 'clockInTime')
                        }
                        value={clockAction.clockInTime}
                        onSelect={(time) =>
                            clockTimeChange('clockInTime', time)
                        }
                        allowClear={true}
                        size="large"
                    />
                    <Button
                        onClick={(e) => clockTimeChange('clockInTime', null)}
                        color="primary"
                        style={{ marginLeft: '0.5em' }}
                    >
                        Clear
                    </Button>
                    {/*
                    <br />
                    <br />
                    <FormLabel component="legend">Notes:</FormLabel>
                    <TextField
                        style={{ width: '100%' }}
                        multiline
                        onChange={setNote}
                        defaultValue={clockAction.note}
                        placeholder="(optional)"
                    />
                    */}
                    <br />
                    <br />
                    <br />
                    <FormLabel
                        component="legend"
                        style={{ fontWeight: 'bolder' }}
                    >
                        Clock-out Time
                    </FormLabel>
                    <TimePicker
                        disabled={isClockOutTimePickerDisabled}
                        style={{ marginTop: '0.5em' }}
                        use12hours
                        format="h:mm a"
                        placeholder="Add clock-out"
                        onClick={(isOpen) =>
                            clockTimeClick(isOpen, 'clockOutTime')
                        }
                        value={clockAction.clockOutTime}
                        onSelect={(time) =>
                            clockTimeChange('clockOutTime', time)
                        }
                        allowClear={true}
                        size="large"
                    />
                    <Button
                        onClick={(e) => clockTimeChange('clockOutTime', null)}
                        color="primary"
                        style={{ marginLeft: '0.5em' }}
                    >
                        Clear
                    </Button>
                    {/*
                    <br />
                    <br />
                    <FormLabel component="legend">Notes:</FormLabel>
                    <TextField
                        style={{ width: '100%' }}
                        multiline
                        onChange={setNote}
                        defaultValue={clockAction.note}
                        placeholder="(optional)"
                    />
                    */}
                    {/*

                    <br />
                    <br />
                    <br />
                    <b> If Nurse Did Not Show For Shift</b>*/}
                    <br />
                    <br />
                    <br />
                    {/*


                        style={{
                            width: '100%',
                            backgroundColor: '#d50000',
                            color: 'white'
                        }}
*/}
                    <Button
                        onClick={handleNurseNoShowOpenConfirm}
                        variant="outlined"
                        color="secondary"
                    >
                        Nurse Did Not Work Shift
                    </Button>
                    <br />
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        {isLoading ? <CircularProgress /> : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleCloseClockDialog}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleSaveTimeDialog}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* no show dialog confirmation */}
            <Dialog
                open={isNoShowDialogOpen}
                onClose={handleCloseNoShowDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    Nurse No-Show Confirmation
                </DialogTitle>
                <DialogContent>
                    Please confirm that this nurse did not show up for their
                    shift.
                    <br />
                    <br />
                    <DialogContentText id="alert-dialog-description" />
                </DialogContent>
                <div style={{ textAlign: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                </div>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleCloseNoShowDialog}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleNurseNoShow}
                        color="primary"
                    >
                        Confirm Nurse No-Show
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClockDialog)
