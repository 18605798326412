// TODO ability to add /remove facility
// ability to update multiple facilities

import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import axios from 'axios'
import { connect } from 'react-redux' //Code
import UserService from '../services/userService.js'
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'
import Utils from '../helpers/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MobileBackButton from '../features/MobileBackButton/MobileBackButton'

class Facility {
    constructor(facility) {
        this.id = facility.id ? facility.id : ''
        this.name = facility.name ? facility.name : ''
        this.address = facility.address ? facility.address : ''
        this.city = facility.city ? facility.city : ''
        this.zipcode = facility.zipcode ? facility.zipcode : ''
        this.phone = facility.phone ? facility.phone : ''
        this.state = facility.state ? facility.state : ''
        this.parkingInstruction = facility.parkingInstruction
            ? facility.parkingInstruction
            : ''
        this.logoUrl = facility.logoUrl ? facility.logoUrl : ''
    }
}
const styles = {
    textField: {
        width: '100%',
        marginBottom: '0.75em',
    },
}

const mapStateToProps = (state) => ({
    user: state.user,
    history: state.history,
})
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
    }
}
const Settings = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        if (!Utils.hasFacilities(props.user)) {
            console.log('no facilities')

            history.push('/onboarding')
        }
    })
    const [facility, setFacility] = useState(
        props.user && props.user.selectedFacility
            ? new Facility(props.user.selectedFacility)
            : {}
    )
    const fileUpload = useRef(null)
    const [file, setFile] = useState('') // storing the uploaded file
    const [isLoading, setIsLoading] = useState(false)
    const handleInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        //console.log('name ' + name)
        setFacility({
            ...facility,
            [name]: value,
        })
    }

    // on page load scroll to top, fix buggy nav
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleFileChange = (e) => {
        const file = e.target.files[0] // accessing file
        console.log(file)
        setFile(file) // storing file
    }

    const updateUser = async (event) => {
        event.preventDefault()
        console.log(facility.name)
        //console.log(this.state)
        setIsLoading(true)
        let res = null

        /*
        const json = JSON.stringify(facility)
        const blob = new Blob([json], {
            type: 'application/json'
        })
        data.append('document', blob)
        */
        const data = new FormData()
        data.append('image', file)
        for (let propertyName in facility) {
            data.append(propertyName, facility[propertyName])
        }

        let headers = await AuthService.getHeader()
        try {
            res = await axios.put(`/api/facility/${facility.id}`, data, {
                headers: headers,
            })
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        setIsLoading(false)
    }
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '1em',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
            }}
        >
            <MobileBackButton topSafeArea={props.topSafeArea} />
            <h2>Account</h2>
            <br />
            <form onSubmit={updateUser}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={facility.name}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Healthcare Facility"
                            name="name"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={facility.address}
                            onChange={handleInputChange}
                            label="Address"
                            name="address"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.city}
                            onChange={handleInputChange}
                            style={styles.textField}
                            label="City"
                            name="city"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.state}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="State"
                            name="State"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            style={styles.textField}
                            value={facility.zipcode}
                            onChange={handleInputChange}
                            label="Zipcode"
                            name="zipcode"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            style={styles.textField}
                            value={facility.phone}
                            onChange={handleInputChange}
                            label="Phone"
                            name="phone"
                            type="phone"
                            placeholder="+15671234"
                            variant="outlined"
                        />
                    </Grid>
                    {/*
                            helperText="If a nurse has questions about a shift, the call will be directed to this number"
                            */}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={facility.parkingInstruction}
                            onChange={handleInputChange}
                            label="Parking Instructions"
                            name="parkingInstruction"
                            multiline
                            rows={4}
                            placeholder="(Optional) Ex. Please park on the south east corner by Western and Carson Blvd. There's free parking there."
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <img
                            src={facility.logoUrl}
                            alt=""
                            onClick={() => {
                                fileUpload.current.click()
                            }}
                            style={{ borderRadius: '1em' }}
                        />
                        <br />
                        <br />
                        <Button variant="contained" component="label">
                            Upload facility logo/image
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden
                                ref={fileUpload}
                            />
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <h2>User Profile</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            style={styles.textField}
                            value={props.user.firstName}
                            onChange={handleInputChange}
                            label="First Name"
                            name="firstName"
                            disabled
                            placeholder=""
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            style={styles.textField}
                            value={props.user.lastName}
                            onChange={handleInputChange}
                            label="Last Name"
                            name="lastName"
                            disabled
                            placeholder=""
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            style={styles.textField}
                            value={props.user.email}
                            onChange={handleInputChange}
                            label="Email"
                            name="email"
                            disabled
                            placeholder=""
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={props.user.phone}
                            onChange={handleInputChange}
                            label="Phone"
                            name="phone"
                            disabled
                            placeholder=""
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        *If you'd like to make changes to your email or phone
                        number please contact support@nurselab.co.
                    </Grid>
                    <Grid item xs={12} md={12} />
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <Button
                            type="submit"
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </Grid>
                </Grid>
            </form>
            <br />
            <br />
            <br />
        </Container>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
