import { createSlice } from '@reduxjs/toolkit'

export const reviewDialogSlice = createSlice({
    name: 'reviewShiftDialog',
    initialState: {
        isOpen: false,
        status: '',
        shiftCollection: {},
        shiftRequest: { user: {} },
    },
    reducers: {
        openReviewDialog: (state, action, status) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            //console.log('opening review dialog in slice')
            state = {
                ...state,
                isOpen: true,
                shift: action.payload.shift ? action.payload.shift : null,
                shiftRequest: action.payload.shiftRequest,
                shiftCollection: action.payload.shiftCollection,
            }

            return state
        },
        updateShift: (state, action) => {
            state = {
                ...state,
                shift: action.payload,
            }
            return state
        },
        closeReviewDialog: (state, action) =>
            (state = {
                ...state,
                isOpen: false,
                shiftRequest: { user: {} },
                shiftCollection: {},
            }),
    },
})

export const isOpen = (state) => state
export const { openReviewDialog, closeReviewDialog, updateShift } =
    reviewDialogSlice.actions

export default reviewDialogSlice.reducer
