import React, { useState, useRef } from 'react'

import { withRouter } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'

import Button from '@mui/material/Button'
import axios from 'axios'
import { Auth } from 'aws-amplify'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code
import userService from '../services/userService.js'
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Utils from '../helpers/utils.js'

import {
    hideBottomNav,
    showBottomNav,
} from '../features/BottomNav/bottomNavSlice'

const useStyles = (theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    textField: {
        width: '100%',
    },
})

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (userState) => {
            dispatch(setUser(userState))
        },
        hideBottomNav: () => {
            dispatch(hideBottomNav())
        },
        showBottomNav: () => {
            dispatch(showBottomNav())
        },
    }
}

class Facility {
    constructor() {
        this.name = ''
        this.address = ''
        this.city = ''
        this.zipcode = ''
        this.state = ''
        this.parkingInstruction = ''
        this.logoUrl = ''
    }
}

const Onboarding = (props) => {
    const [facility, setFacility] = useState(new Facility())
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const fileUpload = useRef(null)
    const [file, setFile] = useState('') // storing the uploaded file

    React.useEffect(() => {
        props.hideBottomNav()
        let firstRun = setInterval(function () {
            if (window.Tawk_API && window.Tawk_API.hideWidget) {
                Utils.hideTawk()
                clearInterval(firstRun)
            }
        }, 300)
        return () => {
            props.showBottomNav()
            Utils.showTawk()
        }
    }, [])

    /*
    constructor(props) {
        super(props)
        this.state = {
            submitDisabled: false,
            facility: new Facility()
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.addFacility = this.addFacility.bind(this)
    }
    */

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length === 0) {
            console.log('returning no file change')
            return
        }
        const file = e.target.files[0] // accessing file

        console.log('file ' + file)
        console.log('file ' + URL.createObjectURL(file))
        setFacility({
            ...facility,
            logoUrl: URL.createObjectURL(file),
        })
        setFile(file) // storing file
    }
    const addFacility = async (event) => {
        event.preventDefault()

        setSubmitDisabled(true)
        console.log(facility.name)
        //console.log(this.state)
        let headers = await AuthService.getHeader()
        let facilityData = { facility: facility }

        const data = new FormData()
        data.append('image', file)
        for (let propertyName in facility) {
            data.append(propertyName, facility[propertyName])
        }

        console.log(facilityData)
        let res = null

        try {
            res = await axios.post('/api/facility/', data, {
                headers: headers,
            })
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        if (res) {
            let userObj = await userService.getAccount()
            //this.props.setUser(userObj)
            // reload user and save user
            //save user state
            props.history.push('/')
        } else {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        setSubmitDisabled(false)
    }

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        console.log('name ' + name)
        let value = event.target.value
        //this.setState({ [name]: event.target.value })

        setFacility({
            ...facility,
            [name]: value,
        })
    }

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const classes = props.classes
    return (
        <Container style={{ paddingTop: isDesktop ? '3em' : 'initial' }}>
            <h2>Facility Information</h2>
            Hi there! You're almost ready to start placing nurses. We just need
            a little more about your facility.
            <br />
            <br />
            <form onSubmit={addFacility}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <TextField
                            id=""
                            value={facility.name}
                            className={classes.textField}
                            onChange={handleInputChange}
                            label="Facility Name"
                            name="name"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField
                            className={classes.textField}
                            value={facility.address}
                            onChange={handleInputChange}
                            label="Address"
                            name="address"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.city}
                            onChange={handleInputChange}
                            className={classes.textField}
                            label="City"
                            name="city"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.state}
                            className={classes.textField}
                            onChange={handleInputChange}
                            label="State"
                            name="state"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            className={classes.textField}
                            value={facility.zipcode}
                            onChange={handleInputChange}
                            label="Zipcode"
                            name="zipcode"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField
                            className={classes.textField}
                            value={facility.parkingInstruction}
                            onChange={handleInputChange}
                            label="Parking Instructions"
                            name="parkingInstruction"
                            multiline
                            rows={4}
                            placeholder="(Optional) Ex. Please park on the south east corner by Western and Carson Blvd. There's free parking there."
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <img
                            src={facility.logoUrl}
                            alt=""
                            onClick={() => {
                                fileUpload.current.click()
                            }}
                            style={{
                                borderRadius: '1em',
                                maxHeight: '12em',
                                marginTop: '1em',
                            }}
                        />
                        <br />
                        <br />
                        Add an optional photo of your facility or organization
                        logo
                        <br />
                        <br />
                        <Button variant="contained" component="label">
                            Add facility image
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden
                                ref={fileUpload}
                            />
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={5}>
                    <Grid item xs={12} md={3}>
                        <Button
                            type="submit"
                            className={classes.textField}
                            label="save"
                            variant="contained"
                            color="primary"
                            disabled={submitDisabled}
                        >
                            Save and Continue
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <br />
            <br />
        </Container>
    )
}

export default compose(
    withStyles(useStyles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Onboarding)
