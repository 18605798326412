import { createSlice } from '@reduxjs/toolkit'

export const fileSlice = createSlice({
    name: 'file',
    initialState: {
        isOpen: false,
        resourceType: '',
        userId: '',
        role: 'admin',
        associate: {},
        doUploadFile: false
    },
    reducers: {
        openFileDialog: (state, action, status) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            state = {
                ...state,
                resourceType: action.payload.resourceType,
                associate: action.payload.associate,
                isOpen: true
            }

            return state
        },
        toggleUpload: (state, action) => {
            state = {
                ...state,
                shift: action.payload.shift ? action.payload.shift : null,
                doUploadFile: true
            }
            return state
        },
        resetUploadToggle: (state, action) => {
            state = {
                ...state,
                doUploadFile: false
            }
            return state
        },
        closeDialog: (state, action) =>
            (state = { ...state, isOpen: false, status: '' })
    }
})

export const isOpen = state => state
export const {
    openFileDialog,
    closeDialog,
    toggleUpload,
    resetUploadToggle
} = fileSlice.actions

export default fileSlice.reducer
