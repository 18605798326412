import { createSlice } from '@reduxjs/toolkit'

export const navStackSlice = createSlice({
    name: 'navStack',
    initialState: {
        stack: [],
        isUnlocked: false,
    },
    reducers: {
        getStackNavStack: (state, action) => {
            return state.stack
        },
        pushNavStack: (state, action) => {
            window.history.pushState(
                { modal: action.payload },
                '/',
                window.location.pathname
            )
            let temp = [...state.stack]
            temp.push(action.payload)

            state = {
                ...state,
                stack: temp,
                isUnlocked: true,
            }
            return state
        },
        peakBackNavStack: (state, action) => {
            if (state.stack.length > 0) {
                let ret = state.stack[state.stack.length - 1]
                return ret
            } else {
                return []
            }
        },
        unlockNavStack: (state, action) => {
            state = {
                ...state,
                isUnlocked: true,
            }
            return state
        },
        popNavStack: (state, action) => {
            let arr = state.stack.slice(0, state.stack.length - 1)
            state = {
                ...state,
                stack: arr,
                isUnlocked: false,
            }
            return state
        },
    },
})

export const {
    getStackNavStack,
    pushNavStack,
    peakBackNavStack,
    popNavStack,
    unlockNavStack,
} = navStackSlice.actions

export default navStackSlice.reducer
