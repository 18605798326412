import { createSlice } from '@reduxjs/toolkit'

export const mailModalSlice = createSlice({
    name: 'mailModal',
    initialState: {
        isOpen: false,
        shiftCollection: {},
    },
    reducers: {
        openMailModal: (state, action) => {
            console.log(action.payload)

            state = {
                ...state,
                isOpen: true,
                shift: action.payload.shift ? action.payload.shift : null,
                associateRequest: action.payload.associateRequest
                    ? action.payload.associateRequest
                    : null,

                shiftCollection: action.payload.shiftCollection,
            }

            return state
        },
        closeMailModal: (state, action) =>
            (state = {
                ...state,
                isOpen: false,
                shift: {},
                associateRequest: {},
                shiftCollection: {},
            }),
    },
})

export const isOpen = (state) => state
export const { openMailModal, closeMailModal } = mailModalSlice.actions

export default mailModalSlice.reducer
