import React, { useState, useRef } from 'react'

import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import Box from '@mui/material/Box'

import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { connect } from 'react-redux' //Code
//import { TimePicker, DatePicker } from 'antd'
import { TimePicker } from 'antd'
import DatePicker from 'react-multi-date-picker'
import { DatePanel } from 'react-multi-date-picker/plugins'

import axios from 'axios'

import userService from '../../services/userService.js'
import authService from '../../services/authService.js'
import Utils from '../../helpers/utils'
//import classes from '../../ant.css'
//import moment from 'moment'
import TzMoment from '../../plugins/TzMoment'

import {
    updateShiftRequest,
    closeShiftRequestDialog,
    openShiftRequestDialog,
    resetShiftRequest,
} from './shiftRequestSlice'

const mapDispatchToProps = (dispatch) => {
    return {
        updateShiftRequest: (state) => {
            dispatch(updateShiftRequest(state))
        },
        closeShiftRequestDialog: (state) => {
            dispatch(closeShiftRequestDialog())
        },
        openShiftRequestDialog: (state) => {
            dispatch(openShiftRequestDialog())
        },
        resetShiftRequest: (state) => {
            dispatch(resetShiftRequest())
        },
    }
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    datePicker: {
        width: '100%',
        marginTop: '0.5em',
    },
    input: {
        marginTop: '0.5em',
    },
}))

const mapStateToProps = (state) => ({
    user: state.user,
    closeShiftRequestDialog: state.closeShiftRequestDialog,
    openShiftRequestDialog: state.openShiftRequestDialog,
    shiftRequest: state.shiftRequest,
})

const ShiftRequest = (props) => {
    const datePickerRef = useRef()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenAlert, setAlertOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    //const [isOpen, setOpen] = useState(false)
    //const [request, setRequest] = useState(new Request())
    let request = props.shiftRequest.shiftRequestData

    let timezone = 'America/Los_Angeles'
    if (props.user.selectedFacility && props.user.selectedFacility.timezone) {
        timezone = props.user.selectedFacility.timezone
    }
    let tzMoment = new TzMoment(timezone)

    //const minDate = tzMoment.moment(new Date())
    //const minDate = new Date()
    let localDate = new Date().getDate()
    let facilityDate = tzMoment.moment(new Date()).format('D')
    let minDate = new Date(tzMoment.moment(new Date()))
    if (localDate != facilityDate) {
        minDate = new Date(tzMoment.moment(new Date()).subtract(1, 'days'))
    }

    const maxDate = new Date(tzMoment.moment(new Date()).add(45, 'days'))

    console.log('request data ' + request)
    console.log(request)

    const setNurseType = (event) => {
        let nurseType = event.target.value
        console.log('nurse type ' + nurseType)
        props.updateShiftRequest({ ...request, type: nurseType })
    }
    const setZone = (event) => {
        let zone = event.target.value
        console.log('zone type ' + zone)
        let data = {}
        if (request.data) {
            data = Object.assign({}, request.data)
        }
        data.zone = zone
        props.updateShiftRequest({ ...request, data: data })
    }
    const setNote = (event) => {
        let txt = event.target.value
        console.log('nurse note' + txt)
        props.updateShiftRequest({ ...request, note: txt })
    }

    const setDescription = (event) => {
        let txt = event.target.value
        console.log('nurse note' + txt)
        props.updateShiftRequest({ ...request, description: txt })
    }

    const setQuantity = (event) => {
        let qty = event.target.value
        console.log('nurse qty' + qty)
        props.updateShiftRequest({ ...request, quantity: qty })
    }

    const setDatePickerDates = (dates) => {
        console.log('dates ' + dates)
        dates = dates.map((date) => {
            //console.log(date)
            date = date.valueOf()
            //console.log('date value')
            //console.log(date)
            return date
        })
        /*
        for (let x = 0; x < dates.length; x++) {
            console.log('per date')
            console.log(new Date(dates[x]))
        }
        */
        props.updateShiftRequest({ ...request, datePickerDates: dates })
    }
    const shiftStartTimeChange = (time) => {
        time = tzMoment.moment(time).valueOf()
        props.updateShiftRequest({
            ...props.shiftRequest.shiftRequestData,
            startTime: time,
        })
    }

    const shiftEndTimeChange = (time) => {
        time = tzMoment.moment(time).valueOf()
        //request.endTime = time
        props.updateShiftRequest({
            ...props.shiftRequest.shiftRequestData,
            endTime: time,
        })
    }

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false)
    }
    const getShiftRanges = () => {
        // get date time w/o timezone attached
        let startTime = tzMoment.moment(request.startTime)
        let sHours = startTime.format('H')
        let sMinutes = startTime.format('m')

        let endTime = tzMoment.moment(request.endTime)
        let eHours = endTime.format('H')
        //let eMinutes = endTime.getMinutes()
        let eMinutes = endTime.format('m')

        let dates = request.datePickerDates.map((epochDate) => {
            // date returned is in EPOCH number format and IN LOCAL MACHINE TIME. so if 12:01 local time might be pushed a day ahead/behind when converted to PST/facility time

            // pull local date w/o timezone info
            let date = new Date(epochDate)
            let dateNum = date.getDate()
            let month = date.getMonth()
            let year = date.getFullYear()

            let startDateTime = tzMoment.moment()

            // add date info to new date obj w/timezone
            startDateTime.set({
                year: year,
                month: month,
                date: dateNum,
                hours: sHours,
                minutes: sMinutes,
                seconds: 0,
                milliseconds: 0,
            })
            //console.log('start time ' + startDateTime)
            /*
            alert(
                date +
                    ' | to date = ' +
                    //toDate +
                    //' | no tz start = ' +
                    //noTzStartDate +
                    ' | startdatetime = ' +
                    startDateTime +
                    ' ' +
                    startDateTime.format('l LT z') +
                    ' | shours =  ' +
                    sHours +
                    ' | ehours = ' +
                    eHours
            )
            */

            let endDateTime = startDateTime.clone()
            endDateTime.set({
                hours: eHours,
                minutes: eMinutes,
                seconds: 0,
                milliseconds: 0,
            })
            //console.log('end time ' + endDateTime)
            if (startDateTime >= endDateTime) {
                //endDateTime = tzMoment.moment(endDateTime)
                endDateTime.add(1, 'days')
                //endDateTime = tzMoment.moment(endDateTime).toDate()
            }
            //console.log('start time ' + startDateTime)
            //console.log('end time ' + endDateTime)

            return [startDateTime, endDateTime]
        })
        //console.log('start time ' + startDateTime)
        //console.log('end time ' + endDateTime)

        return dates
    }
    const isShiftDurationValid = () => {
        let dates = getShiftRanges()

        let startDateTime = dates[0][0]
        let endDateTime = dates[0][1]

        console.log(dates)
        console.log(startDateTime)
        let shiftDuration = tzMoment.moment.duration(
            tzMoment.moment(endDateTime).diff(tzMoment.moment(startDateTime))
        )

        let shiftLength = shiftDuration.hours()
        console.log('duration ' + shiftLength)
        if (shiftLength > 18) {
            setAlertMessage(
                'Sorry, could not place the request. The shift duration exceeds the maximum number of hours.'
            )
            setAlertOpen(true)
            setIsLoading(false)
            return false
        }

        if (shiftLength < 4) {
            setAlertMessage(
                'Sorry, the minimum shift is 4 hours. Please fix and try again.'
            )
            setIsLoading(false)
            setAlertOpen(true)
            return false
        }

        // set request start/end times
        return true
    }
    const createShiftRequest = () => {
        if (!props.user.selectedFacility || !props.user.selectedFacility.id) {
            console.log('error getting facility id')
            return false
        }

        let tmpRequest = JSON.parse(JSON.stringify(request))
        tmpRequest.dates = getShiftRanges()

        tmpRequest.facilityId = props.user.selectedFacility.id

        console.log('facility id ' + props.user.selectedFacility.id)
        let shift = { shift: tmpRequest }
        return shift
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsLoading(true)

        if (!Utils.hasFacilities(props.user)) {
            setIsLoading(false)
            props.closeShiftRequestDialog()
            alert(
                'Before you can place a nurse, please go to the settings and fill out the required information about your facility first. Thank you. '
            )

            setIsLoading(false)
            setAlertOpen(true)
            return
        }
        let headers = await authService.getHeader()

        // checks shift validity also add start/end time to shift request obj
        if (!isShiftDurationValid()) {
            return
        }

        let tmpShiftRequest = createShiftRequest()
        console.log('shift request')
        console.log(tmpShiftRequest)
        if (!tmpShiftRequest) {
            console.log('error creating shift request')
            return
        }

        if (request.isEditExisting) {
            await axios.put('/api/facility/shift/', tmpShiftRequest, {
                headers: headers,
            })
        } else {
            await axios.post('/api/facility/shift/', tmpShiftRequest, {
                headers: headers,
            })
        }
        console.log('facility id ' + tmpShiftRequest.shift.facilityId)
        await userService.getAccount()

        // reset request
        props.resetShiftRequest()
        setIsLoading(false)

        props.closeShiftRequestDialog()
    }

    const loadValues = () => {
        let dates = [tzMoment.moment(request.start).valueOf()]
        console.log('dates ' + dates)
        let start = tzMoment.moment(request.dateTimeRange[0]).valueOf()
        let end = tzMoment.moment(request.dateTimeRange[1]).valueOf()
        props.updateShiftRequest({
            ...props.shiftRequest.shiftRequestData,
            startTime: start,
            endTime: end,
            datePickerDates: dates,
        })
        console.log('save start time')
        console.log(request)
    }
    const handleOpen = () => {
        console.log('handleOpen')
        //console.log(request)

        // prevent shifts from opening until account is enabled again
        if (!props.user.selectedFacility.isEnabled) {
            alert(
                'Sorry, this account has been blocked from placing new shifts until outstanding payments have been cleared. Please contact us at support@nurselab.co for assistance.'
            )
            props.closeShiftRequestDialog()
            return
        }

        // init values
        if (request.isEditExisting) {
            loadValues()
        }
    }
    const handleClose = () => {
        props.closeShiftRequestDialog()
        props.resetShiftRequest()
    }

    const BottomPanel = ({
        state,
        handleChange,
        position,
        calendarProps,
        nodes,
        className,
        ...props
    }) => {
        return (
            <div position="bottom">
                <Button
                    onClick={() => datePickerRef.current.closeCalendar()}
                    style={{ margin: '5px' }}
                >
                    Save
                </Button>
            </div>
        )
    }
    return (
        <div>
            <Dialog
                open={props.shiftRequest.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
                TransitionProps={{
                    onEnter: handleOpen,
                }}
            >
                <div
                    style={{
                        height: props.topSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
                <form
                    style={
                        fullScreen
                            ? {
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                              }
                            : {}
                    }
                    onSubmit={handleSubmit}
                >
                    <DialogTitle id="alert-dialog-title">
                        {request.isEditExisting
                            ? 'Edit Request'
                            : 'Nurse Request'}
                    </DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText id="alert-dialog-description">
                            Nurses on our platform will apply for this.
                        </DialogContentText>
                        */}
                        {request.isEditExisting ? (
                            <div style={{ marginBottom: '2em' }}>
                                *Only the shift's description and notes can be
                                edited. If you'd like to add more nurse(s)
                                please create a new shift request. Contact us at
                                support@nurselab.co if you have any questions.{' '}
                            </div>
                        ) : null}
                        {request.isEditExisting ? (
                            ''
                        ) : (
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} xs={12}>
                                        <FormLabel
                                            component="legend"
                                            margin={2}
                                        >
                                            Date(s)
                                        </FormLabel>

                                        <DatePicker
                                            ref={datePickerRef}
                                            disabled={request.isEditExisting}
                                            multiple={true}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            style={{}}
                                            required
                                            onChange={setDatePickerDates}
                                            value={
                                                request.datePickerDates
                                                    ? request.datePickerDates.map(
                                                          (date) => {
                                                              console.log(
                                                                  'value for date picker date'
                                                              )
                                                              console.log(date)
                                                              return new Date(
                                                                  date
                                                              )
                                                          }
                                                      )
                                                    : [request.date]
                                            }
                                            type={'input-icon'}
                                            className="multiDatePicker"
                                            inputClass="multiDateInput"
                                            containerStyle={{
                                                width: '100%',
                                                verticalAlign: 'middle',
                                            }}
                                            placeholder={'Select Date(s)'}
                                            timePicker={true}
                                            format={'(ddd) MMM D'}
                                            plugins={[
                                                <DatePanel
                                                    position="right"
                                                    sort="date"
                                                    eachDaysInRange
                                                />,

                                                <BottomPanel position="bottom" />,
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <FormLabel component="legend">
                                            Start
                                        </FormLabel>
                                        <TimePicker
                                            disabled={request.isEditExisting}
                                            minuteStep={15}
                                            className={classes.input}
                                            use12hours
                                            format="h:mm a"
                                            size="large"
                                            showNow={false}
                                            value={tzMoment.moment(
                                                request.startTime
                                            )}
                                            onSelect={shiftStartTimeChange}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <FormLabel component="legend">
                                            End
                                        </FormLabel>
                                        <TimePicker
                                            className={classes.input}
                                            disabled={request.isEditExisting}
                                            minuteStep={15}
                                            use12hours
                                            showNow={false}
                                            format="h:mm a"
                                            size="large"
                                            value={tzMoment.moment(
                                                request.endTime
                                            )}
                                            onSelect={shiftEndTimeChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-around" />
                            </Box>
                        )}
                        <br />
                        <br />
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={6}>
                                <FormLabel component="legend">
                                    Type of nurse
                                </FormLabel>
                                <RadioGroup
                                    aria-label="type"
                                    name="nurseType"
                                    style={{ marginLeft: '0.5em' }}
                                    onChange={setNurseType}
                                    value={request.type}
                                >
                                    <FormControlLabel
                                        value="RN"
                                        required
                                        disabled={request.isEditExisting}
                                        control={<Radio required={true} />}
                                        label="RN"
                                    />
                                    <FormControlLabel
                                        value="LVN"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="LVN"
                                    />
                                    <FormControlLabel
                                        value="CNA"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="CNA"
                                    />
                                </RadioGroup>
                            </Grid>

                            <Grid item sm={6} xs={6}>
                                <FormLabel component="legend">
                                    What zone is this shift?
                                </FormLabel>
                                <RadioGroup
                                    aria-label="type"
                                    name="zone"
                                    style={{ marginLeft: '0.5em' }}
                                    onChange={setZone}
                                    value={request.data.zone}
                                >
                                    <FormControlLabel
                                        value="Green"
                                        required
                                        disabled={request.isEditExisting}
                                        control={<Radio required={true} />}
                                        label="Green"
                                    />
                                    <FormControlLabel
                                        value="Yellow"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="Yellow"
                                    />
                                    <FormControlLabel
                                        value="Red"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="Red"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <FormLabel component="legend">
                            Number of nurses
                        </FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                type="number"
                                min={1}
                                disabled={request.isEditExisting}
                                defaultValue={request.quantity}
                                onChange={setQuantity}
                                placeholder="1"
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        {/*}
                        <FormLabel component="legend">
                            Job Description
                        </FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                multiline
                                fullWidth
                                defaultValue={request.description}
                                onChange={setDescription}
                                rows={5}
                                placeholder="(optional)"
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        */}
                        <FormLabel component="legend">Notes</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                multiline
                                fullWidth
                                defaultValue={request.note}
                                onChange={setNote}
                                rows={2}
                                placeholder="ex. Check-in with the Jane Doe in the front (optional)"
                                variant="outlined"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isLoading}
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            autoFocus
                        >
                            Submit
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={isOpenAlert}
                        onClose={handleAlertClose}
                        autoHideDuration={6000}
                    >
                        <Alert severity="error" onClose={handleAlertClose}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </form>
                <div
                    style={{
                        height: props.bottomSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftRequest)
