import {
    configureStore,
    getDefaultMiddleware,
    combineReducers,
} from '@reduxjs/toolkit'

//persistStore,
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import shiftModalReducer from '../features/ShiftModal/shiftModalSlice'
import shiftRequestReducer from '../features/ShiftRequest/shiftRequestSlice'
import plivoModalReducer from '../features/PlivoModal/plivoModalSlice'
import mailModalReducer from '../features/MailModal/mailModalSlice'
import cancelShiftDialogReducer from '../features/CancelShiftDialog/cancelShiftDialogSlice'
import profileDialogReducer from '../features/ProfileDialog/profileDialogSlice'
import reviewDialogReducer from '../features/ReviewDialog/reviewDialogSlice'
import clockDialogReducer from '../features/ClockDialog/clockDialogSlice'
import timesheetReducer from '../features/Timesheet/timesheetSlice'
import snackbarMessageReducer from '../features/SnackbarMessage/snackbarMessageSlice'
import bottomNavReducer from '../features/BottomNav/bottomNavSlice'

import userReducer from '../features/User/userSlice.js'
import fileReducer from '../features/File/fileSlice'

// for modals, back button, etc
import navStackReducer from '../features/NavStack/navStackSlice'

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    stateReconciler: autoMergeLevel2,

    //blacklist: ['modal', 'shiftModal', 'plivo', 'user', 'cancelShiftDialog']
    blacklist: [
        'modal',
        'shiftModal',
        'bottomNav',
        'plivo',
        'mailModal',
        'cancelShiftDialog',
        'shiftRequest',
        'profileDialog',
        'reviewDialog',
        'clockDialog',
        'timesheet',
        'file',
        'navStack',
        'snackbarMessage',
    ],
}

export const rootReducer = combineReducers({
    user: userReducer,
    plivo: plivoModalReducer,
    bottomNav: bottomNavReducer,
    shiftModal: shiftModalReducer,
    mailModal: mailModalReducer,
    cancelShiftDialog: cancelShiftDialogReducer,
    shiftRequest: shiftRequestReducer,
    profileDialog: profileDialogReducer,
    reviewDialog: reviewDialogReducer,
    clockDialog: clockDialogReducer,
    timesheet: timesheetReducer,
    snackbarMessage: snackbarMessageReducer,
    file: fileReducer,
    navStack: navStackReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})
