import { createSlice } from '@reduxjs/toolkit'

export const bottomNavSlice = createSlice({
    name: 'bottomNav',
    initialState: {
        doShow: true,
    },
    reducers: {
        showBottomNav: (state, action, status) => {
            //window.location.hash = 'timesheet'

            state = {
                ...state,
                doShow: true,
            }

            return state
        },
        hideBottomNav: (state, action, status) => {
            state = {
                ...state,
                doShow: false,
            }
            return state
        },
    },
})

export const doShow = (state) => state
export const { showBottomNav, hideBottomNav } = bottomNavSlice.actions

export default bottomNavSlice.reducer
