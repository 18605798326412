import {
    ChatFeedProps,
    ChatHeader,
    MessageList,
    MessageForm,
    //WelcomeGif,
    getDateTime,
    formatDateTime,
    Spinner,
    ChatObject,
} from 'react-chat-engine-advanced'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
//import { styles } from './styles'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

import { useEffect, useRef } from 'react'

let styles = {}
const getDescription = (
    chat: ChatObject | undefined,
    timezoneOffset: number = 0
) => {
    if (!chat) {
        return ''
    } else if (
        chat.last_message.created &&
        chat.last_message.created.length > 0
    ) {
        const dateTime = getDateTime(chat.last_message.created, timezoneOffset)
        const formattedDateTime = formatDateTime(dateTime)
        return `Last Active ${formattedDateTime}`
    } else {
        return 'Start of conversation'
    }
}

export const ChatFeed: React.FC<ChatFeedProps> = (props: ChatFeedProps) => {
    const { chat } = props
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const otherPerson =
        chat &&
        chat.people.find((person) => person.person.username !== props.username)

    let cTitle = ''
    if (chat && chat.custom_json) {
        let tmp = {}
        try {
            tmp = JSON.parse(chat.custom_json)
            if (tmp.facilityName) {
                cTitle = `${tmp.nurseFirstName} ${tmp.nurseLastName}, ${tmp.nurseType} re: shift on ${tmp.date}, ${tmp.startTime} to ${tmp.endTime}.`
            }
        } catch (e) {
            console.log('error parsing chat json')
            console.log(e)
            cTitle = chat.title
        }
    } else if (chat && chat.title) {
        cTitle = chat.title
    }

    const title = props.isLoading ? (
        <Spinner />
    ) : !chat ? (
        ''
    ) : chat.is_direct_chat && otherPerson ? (
        otherPerson.person.username
    ) : (
        cTitle
    )

    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        })
    }
    useEffect(() => {
        // if on view w/  chat list don't scroll to bottom
        if (props.isMobileChatListOpen) {
            return
        }

        scrollToBottom()
        console.log('new messages')
        //console.log(JSON.stringify(chatProps))
    }, [props.messages])
    /*
    if (props.renderChatFeed) {
        return <>{props.renderChatFeed(props)}</>
    }
     */

    return (
        <div
            className="ce-chat-feed"
            style={{
                ...styles.style,
                height: !isDesktop && props.isMobileChatListOpen ? 0 : '100%',
                position: 'relative',
                backgroundColor: 'white',
                ...props.style,
            }}
        >
            <ChatHeader
                title={title}
                description={
                    props.isLoading
                        ? 'Loading...'
                        : getDescription(chat, props.timezoneOffset)
                }
                renderChatHeader={props.renderChatHeader}
                /* specify height so text box can be defined */
                style={{
                    paddingTop: !isDesktop ? 0 : '0.5em',
                    ...styles.chatHeaderStyle,
                    ...props.chatHeaderStyle,
                    //height: !isDesktop ? '10em' : '',
                    overflow: 'hidden',
                }}
            />

            <span
                style={{ visibility: props.isLoading ? 'hidden' : 'visible' }}
            >
                <MessageList
                    chat={chat}
                    messages={props.messages}
                    username={props.username}
                    timezoneOffset={props.timezoneOffset}
                    hasMoreMessages={props.hasMoreMessages}
                    onMessageLoaderShow={props.onMessageLoaderShow}
                    onMessageLoaderHide={props.onMessageLoaderHide}
                    onBottomMessageShow={props.onBottomMessageShow}
                    onBottomMessageHide={props.onBottomMessageHide}
                    renderMessageList={props.renderMessageList}
                    renderMessage={props.renderMessage}
                    style={{
                        height: 'calc(100% - 85px - 48px)',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        position: 'relative',
                        backgroundColor: 'white',
                        //...styles.messageListStyle,
                        ...props.messageListStyle,
                    }}
                />
            </span>
            <div id="messageEnd" ref={messagesEndRef} />

            {props.messages.length === 0 && !props.isLoading && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <MailOutlineIcon
                        style={{ fontSize: '18em' }}
                        color="disabled"
                    />
                    <div style={{ fontSize: '2em' }}>
                        No messages, you're all caught up!
                    </div>
                </div>
            )}

            {props.messages.length > 0 && (
                <MessageForm
                    label="Compose message..."
                    username={props.username}
                    onSubmit={props.onMessageFormSubmit}
                    renderMessageForm={props.renderMessageForm}
                    style={{
                        width: '100%',
                        position: 'fixed',
                        paddingTop: '0.5em',
                        borderTop: '1px',
                        borderBottom: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                        bottom: '0px',

                        //...styles.messageFormStyle,
                        ...props.messageFormStyle,
                    }}
                />
            )}
        </div>
    )
}
