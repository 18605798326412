import { createSlice } from '@reduxjs/toolkit'

export const plivoModalSlice = createSlice({
    name: 'plivoModal',
    initialState: {
        isOpen: false,
        toUser: {}
    },
    reducers: {
        openModal: (state, action) => {
            console.log(action.payload)

            state = { ...state, isOpen: true, toUser: action.payload }

            return state
        },
        closeModal: (state, action) => (state = { ...state, isOpen: false })
    }
})

export const isOpen = state => state
export const { openModal, closeModal } = plivoModalSlice.actions

export default plivoModalSlice.reducer
