import React from 'react'
//import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'

//import { AmplifySignOut } from '@aws-amplify/ui-react'

import AppDrawer from './AppDrawer.js'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Utils from './helpers/utils'
//import { Auth } from 'aws-amplify'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(1),
            width: 240,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em',
    },
}))
const mapStateToProps = (state) => ({ user: state.user })

const NavBar = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles()
    let hasCompletedOnboarding = true
    if (!Utils.hasFacilities(props.user)) {
        console.log('no facilities')
        hasCompletedOnboarding = false
    }

    return (
        <div className={classes.root}>
            {hasCompletedOnboarding ? (
                <AppDrawer topSafeArea={props.topSafeArea} />
            ) : null}
        </div>
    )
}
export default compose(connect(mapStateToProps, null))(NavBar)
