import { createSlice } from '@reduxjs/toolkit'

export const profileDialogSlice = createSlice({
    name: 'profileShiftDialog',
    initialState: {
        isOpen: false,
        status: '',
        shiftCollection: {},
        shiftRequest: { user: {} }
    },
    reducers: {
        openProfileDialog: (state, action, status) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            console.log('opening profile dialog in slice')
            state = {
                ...state,
                isOpen: true,
                shift: action.payload.shift ? action.payload.shift : null,
                shiftStatus: action.payload.shiftStatus,
                shiftRequest: action.payload.shiftRequest,
                shiftCollection: action.payload.shiftCollection
            }

            return state
        },
        updateShift: (state, action) => {
            state = {
                ...state,
                shift: action.payload
            }
            return state
        },
        closeProfileDialog: (state, action) =>
            (state = { ...state, isOpen: false, status: '' })
    }
})

export const isOpen = state => state
export const {
    openProfileDialog,
    closeProfileDialog,
    updateShift
} = profileDialogSlice.actions

export default profileDialogSlice.reducer
