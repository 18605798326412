import React, { useEffect } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import { TimePicker } from 'antd'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import UserService from '../../services/userService.js'
import CircularProgress from '@mui/material/CircularProgress'
import { openFileDialog } from '../File/fileSlice'

import {
    openProfileDialog,
    closeProfileDialog,
    updateShift,
} from './profileDialogSlice.js'
import Reviews from '../Reviews/Reviews'

import TzMoment from '../../plugins/TzMoment'
const moment = require('moment')

const mapStateToProps = (state) => ({
    isOpen: state.profileDialog.isOpen,
    shift: state.profileDialog.shift,
    shiftStatus: state.profileDialog.shiftStatus,
    shiftCollection: state.profileDialog.shiftCollection,
    shiftRequest: state.profileDialog.shiftRequest,
})

class ClockAction {
    constructor(type, shiftCollection, shift) {
        let tzMoment = new TzMoment(shiftCollection.timezone)
        this.type = type ? type : ''
        if (type === 'clockInTime') {
            this.name = 'Check-in'
            this.time =
                shift && shift.clockInTime
                    ? tzMoment.moment(shift.clockInTime)
                    : tzMoment.moment(shiftCollection.start)
        } else if (type === 'clockOutTime') {
            this.name = 'Check-out'
            this.time =
                shift && shift.clockOutTime
                    ? tzMoment.moment(shift.clockOutTime)
                    : tzMoment.moment(shiftCollection.end)
        } else {
            this.name = ''
            this.time = null
        }
        this.note = ''
        console.log('type ' + type)
        //console.log('test jasdfasdf')
        //console.log(this.time)
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: () => {
            dispatch(openProfileDialog())
        },

        closeDialog: () => {
            dispatch(closeProfileDialog())
        },

        updateShift: (shift) => {
            dispatch(updateShift(shift))
        },
        openFileDialog: (resourceType, associate) => {
            dispatch(
                openFileDialog({
                    resourceType: resourceType,
                    associate: associate,
                })
            )
        },
    }
}
const ProfileDialog = (props) => {
    const shiftRequest = props.shiftRequest

    const associate = shiftRequest.user

    const shiftCollection = props.shiftCollection
    const [isLoading, setIsLoading] = React.useState(false)
    //const [clockDetails, setClockDetails] = React.useState('')
    const [clockInTime, setClockInTime] = React.useState('')
    const [clockOutTime, setClockOutTime] = React.useState('')

    let tmpDoNotSend = false
    if (associate.isDoNotSend) {
        tmpDoNotSend = associate.isDoNotSend
    }
    const [isDoNotSend, setIsDoNotSend] = React.useState(tmpDoNotSend)
    const [isTimeDialogOpen, setIsTimeDialogOpen] = React.useState(false)
    const [isNoShowDialogOpen, setIsNoShowDialogOpen] = React.useState(false)
    const [clockAction, setClockAction] = React.useState({})
    /*
    const handleConfirmCancelNurseShiftRequest = shiftId => {
        setShiftId(shiftId)
        //setIsOpenCancelShiftDialog(true)
        props.openDialog()
        return
    }
    */

    let blsButton = ''
    let nurseLicenseButton = ''
    let hepatitisBVaccineRecordButton = '',
        tbTestRecordButton = '',
        resumeButton = '',
        fluVaccineRecordButton = '',
        covidVaccineRecordButton = ''
    let availableFiles = associate.availableFiles
        ? associate.availableFiles
        : []
    let noFiles = ''
    if (availableFiles.length === 0) {
        noFiles =
            'Please contact support@nurselab.co for credentials, records and licenses for this nurse.'
    }
    const handleOpenFileDialog = async (resourceType) => {
        props.openFileDialog(resourceType, associate)
    }
    availableFiles.forEach((file) => {
        switch (file) {
            case 'bls':
                blsButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            style={{ width: '100%' }}
                            variant="contained"
                        >
                            Basic Life Support Certification
                        </Button>
                        <br />
                    </div>
                )

                break
            case 'tbTestRecord':
                tbTestRecordButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            style={{ width: '100%' }}
                            variant="contained"
                        >
                            TB Test Record
                        </Button>
                        <br />
                    </div>
                )

                break
            case 'fluVaccineRecord':
                fluVaccineRecordButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            style={{ width: '100%' }}
                            variant="contained"
                        >
                            Flu Immunization Record
                        </Button>
                        <br />
                    </div>
                )

                break
            case 'covidVaccineRecord':
                covidVaccineRecordButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            style={{ width: '100%' }}
                            variant="contained"
                        >
                            Covid Vaccine Record
                        </Button>
                        <br />
                    </div>
                )

                break
            case 'nurseLicense':
                nurseLicenseButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            variant="contained"
                            style={{ width: '100%' }}
                        >
                            Nurse License
                        </Button>
                        <br />
                    </div>
                )

                break
            case 'hepatitisBVaccineRecord':
                hepatitisBVaccineRecordButton = (
                    <div>
                        <br />

                        <Button
                            onClick={() => handleOpenFileDialog(file)}
                            color="primary"
                            style={{ width: '100%' }}
                            variant="contained"
                        >
                            Hepatitis B Immunization Record
                        </Button>
                        <br />
                    </div>
                )

                break
            default:
                break
        }
    })

    let tzMoment = new TzMoment(props.shiftCollection.timezone)

    useEffect(() => {
        const renderClockDetails = () => {
            console.log('rendering lcokc details')
            let clockInDetails = ''
            let clockOutDetails = ''
            if (props.shift && props.shift.clockInTime) {
                // only allow updates 30 mins before shift starts

                let clockIn = tzMoment
                    .moment(props.shift.clockInTime)
                    .format('h:mma')
                let clockInNotes = props.shift.clockInTimeNote
                    ? 'Notes: ' + props.shift.clockInTimeNote
                    : ''

                clockInDetails = (
                    <div>
                        Added check-in time: {clockIn}
                        <br />
                        {clockInNotes}
                    </div>
                )
                console.log('calculate clock in' + clockIn)
            }

            if (props.shift && props.shift.clockOutTime) {
                let clockOut = tzMoment
                    .moment(props.shift.clockOutTime)
                    .format('h:mma')
                let clockOutNotes = props.shift.clockOutTimeNote
                    ? 'Notes: ' + props.shift.clockOutTimeNote
                    : ''
                clockOutDetails = (
                    <div>
                        Added check-out time: {clockOut}
                        <br />
                        {clockOutNotes}
                    </div>
                )

                console.log('calculate clock out ' + clockOut)
            }
            setClockOutTime(clockOutDetails)
            setClockInTime(clockInDetails)
        }

        renderClockDetails()
    }, [props.shift])
    const handleCloseDialog = () => {
        props.closeDialog()
    }
    const handleCloseTimeDialog = () => {
        setIsTimeDialogOpen(false)
    }

    const handleSaveTimeDialog = async () => {
        // determin if PM or am
        console.log(clockAction.time)

        setIsLoading(true)
        let data = {}

        let clockTime = tzMoment.moment(clockAction.time)
        if (clockAction.type === 'clockInTime') {
            if (clockTime.isAfter(tzMoment.moment(shiftCollection.end))) {
                console.log('test')
                clockTime.subtract(1, 'days')
                clockTime = tzMoment.moment(clockTime).toDate()
            }
            data = {
                clockInTime: clockTime,
                clockInTimeNote: clockAction.note,
            }
        } else if (clockAction.type === 'clockOutTime') {
            //console.log('testing')
            //console.log(shiftCollection.start)
            //console.log(shiftCollection.startTime)
            //console.log(clockTime)
            if (tzMoment.moment(shiftCollection.start).isAfter(clockTime)) {
                clockTime.add(1, 'days')
                clockTime = tzMoment.moment(clockTime).toDate()
            }
            data = {
                clockOutTime: clockTime,
                clockOutTimeNote: clockAction.note,
            }
        } else {
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
            return
        }

        console.log('clock time ' + clockTime)
        let headers = await AuthService.getHeader()
        try {
            let res = await axios.put(
                `/api/facility/shift/${props.shift.id}`,
                data,
                {
                    headers: headers,
                }
            )
            let shiftObj = res.data
            console.log('updates shig')
            console.log(shiftObj)
            props.updateShift(shiftObj)
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
        }

        //update data
        await UserService.getAccount()
        console.log('inspect clock action')
        console.log(clockAction)
        setIsTimeDialogOpen(false)

        //renderClockDetails()
        setIsLoading(false)
    }
    const handleOpenTimeDialog = (type) => {
        // clockiinTime clockoutTime

        let diff = moment.duration(
            moment(props.shiftCollection.start).diff(moment(new Date()))
        )
        let diffMins = diff.asMinutes()
        console.log(diffMins)
        if (type === 'clockInTime') {
            if (diffMins > 90) {
                alert(
                    'You can only check-in the nurse up to 90 mins before the shift starts.'
                )
                return
            }
        } else {
            if (diffMins > 0) {
                alert(
                    'You can only check-out the nurse after the shift starts.'
                )
                return
            }
        }

        let shift = props.shift ? props.shift : null
        let clockA = new ClockAction(type, shiftCollection, shift)
        setClockAction(clockA)
        console.log('handle type ' + type)
        console.log('handle clock ' + clockA.time)
        setIsTimeDialogOpen(true)
    }

    const handleNurseNoShowOpenConfirm = (type) => {
        let timeAfterStart = moment.duration(
            moment(new Date()).diff(moment(props.shiftCollection.start))
        )
        let minsAfterStart = timeAfterStart.asMinutes()
        console.log(minsAfterStart)
        if (minsAfterStart < 60) {
            alert(
                'You need to wait at least 60 minutes after the start of the shift before you can mark a nurse as no-show.'
            )
            return
        }

        setIsNoShowDialogOpen(true)
    }
    const handleNurseNoShow = async (type) => {
        let data = { isNoShow: true }

        let headers = await AuthService.getHeader()
        try {
            let res = await axios.put(
                `/api/facility/shift/${props.shift.id}`,
                data,
                {
                    headers: headers,
                }
            )
            let shiftObj = res.data
            console.log('updates shig no show')
            console.log(shiftObj)
            props.updateShift(shiftObj)
        } catch (e) {
            console.log(e)
            alert(
                'Sorry, there was an error. Please contact us at support@nurselab.co. Thank you!'
            )
        }

        setIsNoShowDialogOpen(false)

        handleCloseDialog()
        alert(
            "We're sorry for the inconvenience caused by this nurse not showing. You will not be billed for this nurse."
        )
    }
    const handleCloseNoShowDialog = () => {
        setIsNoShowDialogOpen(false)
    }

    const clockTimeChange = (time) => {
        //clockAction.time = time
        console.log('clock change event ' + time)
        setClockAction({ ...clockAction, time: time })

        console.log('inspecing clock action')
        console.log(clockAction)
    }

    const setNote = (event) => {
        let txt = event.target.value
        console.log('note' + txt)
        setClockAction({ ...clockAction, note: txt })
    }
    const handleCancelShiftRequest = async () => {
        let headers = await AuthService.getHeader()
        setIsLoading(true)
        //console.log(shiftId)
        await axios.delete(`/api/facility/shift/${shiftRequest.shift.id}`, {
            headers: headers,
        })
        //reload user data
        await UserService.getAccount()
        setIsLoading(true)
        props.closeDialog()
        //setIsOpenCancelShiftDialog(false)
    }

    const fullName = `${shiftRequest.user.firstName}  ${shiftRequest.user.lastName}, ${shiftCollection.type}`
    const doShowLicense = shiftCollection.type !== 'CNA' ? true : false

    const handleDoNotSend = async (event) => {
        const name = event.target.name
        console.log('name ' + name + ' do not send ? ' + isDoNotSend)
        let tmpDoNotSend = !isDoNotSend
        setIsDoNotSend(tmpDoNotSend)
        console.log(
            'name ' + name + ' do not send ? ' + tmpDoNotSend,
            isDoNotSend
        )
        let headers = await AuthService.getHeader()
        let data = {
            associateId: associate.id,
            facilityId: shiftCollection.facility_id,
            isDoNotSend: tmpDoNotSend,
        }
        try {
            await axios.post(`/api/facility/associate/doNotSend/`, data, {
                headers: headers,
            })

            if (tmpDoNotSend) {
                alert(
                    'This nurse will no longer be able to request shifts or see upcoming shifts from your facility. Once you mark this nurse as "Do Not Send" you must cancel all future shifts that you have confirmed for this nurse.'
                )
            }
        } catch (e) {
            console.log('error do not send ' + JSON.stringify(e))
            alert(
                'Error, sorry something went wrong. Please contact us at support@nurselab.co'
            )
        }
    }

    const shiftDateTime = `${shiftCollection.date} from ${shiftCollection.startTime}-
            ${shiftCollection.endTime}.`

    let modifyTimes = ''
    let scheduledShift = ''

    console.log('testing here')
    //console.log(props.shiftStatus)
    //console.log(props.profileDialog)
    //

    if (props.shiftStatus === 'confirmed') {
        scheduledShift = (
            <div>
                <b>Scheduled Shift</b>:<br />
                {shiftDateTime}
                <br />
                <br />
            </div>
        )
        if (clockInTime || clockOutTime) {
            modifyTimes = (
                <div>
                    {clockInTime}
                    {clockOutTime}
                    <br />
                </div>
            )
        }
    }
    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div
                    style={{
                        height: props.topSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    {fullName}
                </DialogTitle>
                <DialogContent>
                    <div style={{ textAlign: 'center' }}>
                        <Avatar
                            src={associate.profileImageUrl}
                            style={{
                                height: '8em',
                                width: '8em',
                                marginBottom: '1em',
                                display: 'inline-block',
                            }}
                        />
                    </div>

                    <b> Do Not Send </b>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isDoNotSend}
                                onChange={handleDoNotSend}
                                name="isDoNotSend"
                            />
                        }
                    />
                    <br />

                    {scheduledShift}
                    {props.shift && props.shift.isNoShow ? null : modifyTimes}
                    <b>Credentials & Records</b>

                    <br />
                    {noFiles}
                    {nurseLicenseButton}

                    {resumeButton}
                    {blsButton}
                    {tbTestRecordButton}
                    {fluVaccineRecordButton}
                    {covidVaccineRecordButton}
                    {hepatitisBVaccineRecordButton}
                    <DialogContentText id="alert-dialog-description" />
                    <br />
                    <b>Reviews</b>

                    <div style={{ marginTop: '1em' }}>
                        <Reviews userId={props.shiftRequest.user.id} />
                    </div>

                    <br />
                </DialogContent>
                <div style={{ textAlign: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                </div>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: props.bottomSafeArea + 'px',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                ></div>
            </Dialog>

            {/* checkout/checkin modification times */}

            <Dialog
                open={isTimeDialogOpen}
                onClose={handleCloseTimeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    Nurse {clockAction.name} Time
                </DialogTitle>
                <DialogContent>
                    <b>Scheduled Shift</b>:<br />
                    {shiftDateTime}
                    <br />
                    <br />
                    <FormLabel component="legend">
                        {clockAction.name} Time:
                    </FormLabel>
                    <TimePicker
                        style={{ marginTop: '0.5em' }}
                        use12hours
                        format="h:mm a"
                        value={clockAction.time}
                        onSelect={clockTimeChange}
                        size="large"
                    />
                    <br />
                    <br />
                    <FormLabel component="legend">Notes:</FormLabel>
                    <TextField
                        style={{ width: '100%' }}
                        multiline
                        onChange={setNote}
                        defaultValue={clockAction.note}
                        placeholder="(optional)"
                    />
                    <br />
                    <br />
                    <DialogContentText id="alert-dialog-description" />
                </DialogContent>
                <div style={{ textAlign: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                </div>
                <DialogActions>
                    <Button onClick={handleCloseTimeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveTimeDialog} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* no show dialog confirmation */}
            <Dialog
                open={isNoShowDialogOpen}
                onClose={handleCloseNoShowDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center' }}
                >
                    Nurse No-Show Confirmation
                </DialogTitle>
                <DialogContent>
                    Please confirm that this nurse did not show up for their
                    shift.
                    <br />
                    <br />
                    <DialogContentText id="alert-dialog-description" />
                </DialogContent>
                <div style={{ textAlign: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                </div>
                <DialogActions>
                    <Button onClick={handleCloseNoShowDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNurseNoShow} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDialog)
