//import React from 'react';

/*
import { ChatListProps } from './props';
import { styles } from './styles';

import { ChatForm } from './ChatForm';
import { ChatCard } from './ChatCard';

import { RenderTrigger } from '../../Components/RenderTrigger';

import { ChatObject } from '../../../interfaces';
import { getDateTime } from '../../util/dateTime';

import { Spinner } from '../../Components/Spinner';
 */
import {
    RenderTrigger,
    ChatForm,
    ChatCard,
    ChatObject,
    ChatListProps,
    getDateTime,
    Spinner,
} from 'react-chat-engine-advanced'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const readLastMessage = (username: string, chat: ChatObject) => {
    return chat.people.some(
        (chatPerson) =>
            chatPerson.person.username === username &&
            chatPerson.last_read === chat.last_message.id
    )
}
const renderLoading = () => {
    return [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, i) => {
        return <ChatCard key={`chat_${i}`} isLoading={true} />
    })
}

const getDescription = (chat: ChatObject): string => {
    if (!chat.last_message.id) {
        return 'Start of conversation'
    }
    if (chat.last_message.text === null) {
        return `${chat.last_message.attachments.length} attachments`
    }
    return chat.last_message.text
}

export const ChatList: React.FC<ChatListProps> = (props: ChatListProps) => {
    const { activeChatId = -1 } = props

    const renderChats = (chats: Array<ChatObject>) => {
        if (chats.length === 0) {
            /*
                <ChatCard
                    key={`chat_none`}
                    chat={'nothing here'}
                    title="Nothing here"
                    description="empty chat"
                    isActive={false}
                    hasNotification={false}
                    avatarUsername={props.username}
                    avatarUrl={
                        'https://chat-engine-assets.s3.amazonaws.com/empty-chat-thumb.png'
                    }
                    renderChatCard={props.renderChatCard}
                />
                 */
            return (
                <div style={{ paddingLeft: '1em' }}>
                    You have no new messages from nurses.
                </div>
            )
        }

        return chats.map((chat, index) => {
            const otherPerson =
                chat &&
                chat.people.find(
                    (person) => person.person.username !== props.username
                )

            let cTitle = ''
            if (chat && chat.custom_json) {
                let tmp = {}
                try {
                    tmp = JSON.parse(chat.custom_json)
                    if (tmp.facilityName) {
                        cTitle = `${tmp.nurseFirstName} ${tmp.nurseLastName}, ${tmp.nurseType} re: shift on ${tmp.date}, ${tmp.startTime} to ${tmp.endTime}.`
                    }
                } catch (e) {
                    console.log('error parsing chat json')
                    console.log(e)
                    cTitle = chat.title
                }
            } else if (chat && chat.title) {
                cTitle = chat.title
            }

            const title = !chat
                ? ''
                : chat.is_direct_chat && otherPerson
                ? otherPerson.person.username
                : cTitle
            const timeStamp = getDateTime(chat.created, props.timezoneOffset)
                .toString()
                .substr(4, 6)
            const hasNotification = props.username
                ? !readLastMessage(props.username, chat)
                : false

            return (
                <ChatCard
                    style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
                    key={`chat_${index}`}
                    chat={chat}
                    title={title}
                    description={getDescription(chat)}
                    timeStamp={timeStamp}
                    isActive={activeChatId === chat.id}
                    hasNotification={hasNotification}
                    onClick={() =>
                        props.onChatCardClick && props.onChatCardClick(chat.id)
                    }
                    avatarUsername={chat.last_message.sender?.first_name}
                    avatarUrl={
                        chat.last_message.sender
                            ? chat.last_message.sender.avatar
                            : 'https://chat-engine-assets.s3.amazonaws.com/empty-chat-thumb.png'
                    }
                    renderChatCard={props.renderChatCard}
                />
            )
        })
    }

    /*
    if (props.renderChatList) {
        return <>{props.renderChatList(props)}</>
    }

    alert('run?')
     */
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <div
            className="ce-chat-list"
            style={{
                // Default
                //...styles.style,
                backgroundColor: 'white',
                height: '100%',
                // State
                ...(!isDesktop ? { width: '100%' } : {}),
                ...(props.isLoading ? { overflowY: 'hidden' } : {}),
                // Props
                ...props.style,
            }}
        >
            <ChatForm
                onFormSubmit={props.onChatFormSubmit}
                renderChatForm={props.renderChatForm}
            />

            {props.isLoading ? renderLoading() : renderChats(props.chats)}

            {props.hasMoreChats && (
                <RenderTrigger
                    onShow={props.onChatLoaderShow}
                    style={{
                        //...styles.loadingMoreChatsStyle,
                        backgroundColor: 'white',
                        ...props.loadingMoreChatsStyle,
                    }}
                    children={<Spinner />}
                />
            )}
        </div>
    )
}
