import { createSlice } from '@reduxjs/toolkit'
import TzMoment from '../../plugins/TzMoment'

const tzMoment = new TzMoment('America/Los_Angeles')

//to do rename all of slice shift collection
const shiftCollection = {
    id: '',
    type: '',
    dates: [],
    datePickerDates: [],
    note: '',
    description: '',
    quantity: 1,
    startDateTime: null,
    // todo dynamically set tzmoment to facility timezone? is this necessary?
    startTime: tzMoment.moment('7:00', 'HH:mm').valueOf(),
    endTime: tzMoment.moment('15:00', 'HH:mm').valueOf(),
    endDateTime: null,
    data: { zone: '' },
}
const initialState = {
    isOpen: false,
    shiftRequestData: shiftCollection,
}

export const shiftRequestDialogSlice = createSlice({
    name: 'shiftRequestDialog',
    initialState: initialState,
    reducers: {
        resetShiftRequest: (state) => initialState,
        openShiftRequestDialog: (state, action, status) => {
            // console.log('action pyaload')
            console.log('opening profile dialog in slice')
            state = {
                ...state,
                isOpen: true,
            }

            return state
        },
        updateShiftRequest: (state, action) => {
            state = {
                ...state,
                shiftRequestData: action.payload,
            }
            return state
        },
        closeShiftRequestDialog: (state, action) =>
            (state = { ...state, isOpen: false }),
    },
})

export const isOpen = (state) => state
export const {
    openShiftRequestDialog,
    closeShiftRequestDialog,
    updateShiftRequest,
    resetShiftRequest,
} = shiftRequestDialogSlice.actions

export default shiftRequestDialogSlice.reducer
