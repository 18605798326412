import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import { useHistory } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Capacitor } from '@capacitor/core'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = null

const MobileBackButton = ({ topSafeArea }) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    let backArrow = <ArrowBack />

    if (Capacitor.getPlatform() === 'ios') {
        backArrow = <ArrowBackIosNewIcon />
    }

    const goBack = () => {
        window.history.back()
    }
    let top = topSafeArea + 'px'

    return (
        <>
            {!isDesktop ? (
                <div style={{ marginBottom: '4em' }}>
                    <div
                        position="fixed"
                        style={{
                            position: 'fixed',
                            top: top,
                            left: 0,
                            backgroundColor: 'white',
                            zIndex: 2,
                            paddingBottom: '0.5em',
                            paddingTop: '0.5em',
                            borderWidth: '0 0 1px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(235, 235, 235)',
                            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 4px 4px -4px',
                            width: '100%',
                        }}
                    >
                        <Button
                            variant="text"
                            style={{
                                color: 'black',
                                left: 0,
                                paddingLeft: 0,
                            }}
                            onClick={goBack}
                        >
                            {backArrow}
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    MobileBackButton
)
