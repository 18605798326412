import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux' //Code

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'

import Typography from '@mui/material/Typography'
import axios from 'axios'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AssociateCard from '../AssociateCard/AssociateCard.js'
import PlacedAssociateCard from '../AssociateCard/PlacedAssociateCard.js'
import Box from '@mui/material/Box'
import AuthService from '../../services/authService.js'
import userService from '../../services/userService.js'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'

import {
    updateShiftRequest,
    openShiftRequestDialog,
} from '../ShiftRequest/shiftRequestSlice'

const moment = require('moment')
const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => {
    return {
        updateShiftRequest: (state) => {
            dispatch(updateShiftRequest(state))
        },
        openShiftRequestDialog: (state) => {
            dispatch(openShiftRequestDialog())
        },
    }
}

const JobListing = (props) => {
    /* popover */
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
    const [popoverText, setPopoverText] = React.useState('')
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
    /* end pop over */

    let shiftCollection = props.shiftCollection
    const [isOpenCancelShiftDialog, setIsOpenCancelShiftDialog] =
        useState(false)

    const [cancelMessage, setCancelMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    //const [shiftCarousel, setShiftCarousel] = useState('')

    let isPastShift = false
    if (!moment(shiftCollection.end).isAfter(new Date())) {
        isPastShift = true
    }
    /*
    useEffect(
        () => {
            const renderCarousel = () => {
                if (shiftCollection.isCanceled) {
                    setShiftCarousel('')
                }
                let carousel = (
                    <ShiftCarousel
                        isPastShift={isPastShift}
                        requests={shiftCollection.requests}
                        shiftCollection={shiftCollection}
                    />
                )
                setShiftCarousel(carousel)
            }
            renderCarousel()
        },
        [shiftCollection, isPastShift]
    )
    */

    let shiftCarousel = ''
    if (!shiftCollection.isCanceled) {
        shiftCarousel = (
            <ShiftCarousel
                isPastShift={isPastShift}
                requests={shiftCollection.requests}
                shiftCollection={shiftCollection}
            />
        )
    }
    const handleCancelShiftDialogClose = () => {
        setIsOpenCancelShiftDialog(false)
    }

    let tmpBookedRequests = shiftCollection.requests.filter(
        (request) => request.isActive
    )
    let quantityBooked = tmpBookedRequests.length
    let remaining = shiftCollection.quantity - quantityBooked

    let isShiftFullyBooked = getIsFullyBooked(
        tmpBookedRequests.length,
        shiftCollection.quantity
    )

    // SHIFT COLLECTION
    const handleConfirmCancelNurseShiftRequest = (shiftCollectionId) => {
        let msg = ''

        if (remaining === 0) {
            msg = `Please cancel the nurses already booked on this shift first before canceling the shift request.`
        } else if (quantityBooked === 0) {
            let type = `this ${shiftCollection.type} shift`
            if (shiftCollection.quantity > 1) {
                type = `these ${shiftCollection.type} shifts`
            }
            msg = `Please confirm that you'd like to cancel ${type} on 
            ${shiftCollection.date} from ${shiftCollection.startTime}-
            ${shiftCollection.endTime}?`
        } else {
            let position = 'position'
            if (remaining > 0) {
                position = 'positions'
            }
            msg = `Clicking cancel will cancel the ${remaining} unfilled open ${position}.`
        }
        setCancelMessage(msg)
        setIsOpenCancelShiftDialog(true)
        return
    }

    const handleCancelShiftRequest = async () => {
        let headers = await AuthService.getHeader()
        setIsLoading(true)

        let remaining = shiftCollection.quantity - quantityBooked
        let data = { quantity: remaining }
        //console.log(shiftId)
        await axios.delete(
            `/api/facility/shift/collection/${shiftCollection.id}`,
            {
                headers: headers,
                data: data,
            }
        )
        //reload user data
        await userService.getAccount()
        setIsLoading(false)
        setIsOpenCancelShiftDialog(false)
    }

    const handleEditShiftCollection = () => {
        // todo rename shift request so shift collection
        let tmpShiftCollection = JSON.parse(
            JSON.stringify(props.shiftCollection)
        )
        tmpShiftCollection.isEditExisting = true
        console.log(tmpShiftCollection)
        props.updateShiftRequest(tmpShiftCollection)
        props.openShiftRequestDialog()
    }

    const handlePopoverOpen = (event, text) => {
        setPopoverText(text)
        setPopoverAnchorEl(event.currentTarget)
        setIsPopoverOpen(true)
    }
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        setIsPopoverOpen(false)
    }
    let description = null
    if (shiftCollection.description) {
        description = shiftCollection.description
        if (shiftCollection.description.length > 50) {
            let synopsis = shiftCollection.description.substring(0, 50) + '... '
            description = (
                <span>
                    {synopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={(e) =>
                            handlePopoverOpen(e, shiftCollection.description)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </span>
            )
        }
    }
    let note = null
    if (shiftCollection.note) {
        note = shiftCollection.note

        if (shiftCollection.note.length > 50) {
            let noteSynopsis = shiftCollection.note.substring(0, 50) + '... '
            note = (
                <span>
                    {noteSynopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={(e) =>
                            handlePopoverOpen(e, shiftCollection.note)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </span>
            )
        }
    }
    return (
        <div>
            <Box
                mt={3}
                p={2}
                style={{
                    borderColor: '#dcdcdc',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '0.5em',
                    backgroundColor: '#f3f6f9',
                }}
            >
                <Grid
                    direction={'row'}
                    container
                    spacing={1}
                    style={{
                        maxHeight: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Grid
                        item
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                        }}
                        xs={12}
                        sm={3}
                    >
                        <Card
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '100%',
                                flexDirection: 'column',
                                margin: '2px',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h3"
                                    style={{
                                        color: 'red',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {shiftCollection.isCanceled ? (
                                        <div>Canceled </div>
                                    ) : (
                                        ''
                                    )}
                                    {/*} {shift.status}*/}
                                </Typography>
                                <Typography variant="h6" component="h6">
                                    {shiftCollection.date}
                                    <br />
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    component="p"
                                >
                                    🕗 {shiftCollection.startTime}-
                                    {shiftCollection.endTime}
                                    <br />
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    ⚕️ Position: {shiftCollection.type}
                                    <br />
                                </Typography>
                                {/*
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {shiftCollection.quantity
                                        ? 'Number of Nurses Requested: ' +
                                          shiftCollection.quantity
                                        : ''}
                                    <br />
                                </Typography>
                                */}

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{
                                        color: isShiftFullyBooked
                                            ? 'gray'
                                            : 'orange',
                                    }}
                                >
                                    {quantityBooked === shiftCollection.quantity
                                        ? '✔️'
                                        : '❌'}{' '}
                                    Staff Requested/Filled: {quantityBooked} of{' '}
                                    {shiftCollection.quantity}
                                </Typography>
                                {shiftCollection.data &&
                                shiftCollection.data.zone ? (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        🚦 Zone: {shiftCollection.data.zone}
                                    </Typography>
                                ) : null}

                                <br />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {description ? (
                                        <span>
                                            📘 Description: {description}
                                        </span>
                                    ) : null}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {note ? (
                                        <span>📝 Notes: {note}</span>
                                    ) : null}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {shiftCollection.isCanceled || isPastShift ? (
                                    ''
                                ) : (
                                    <div>
                                        <Tooltip title="Cancel Shift">
                                            <Button
                                                onClick={() =>
                                                    handleConfirmCancelNurseShiftRequest(
                                                        shiftCollection.id
                                                    )
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title="Edit shift details and notes">
                                            <Button
                                                onClick={() =>
                                                    handleEditShiftCollection()
                                                }
                                            >
                                                Edit Details
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </CardActions>
                            <Popover
                                id="mouse-over-popover"
                                open={isPopoverOpen}
                                anchorEl={popoverAnchorEl}
                                style={{
                                    pointerEvents: 'none',
                                    maxWidth: '500px',
                                    minWidth: '50%',
                                }}
                                disableRestoreFocus
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography style={{ padding: '1em' }}>
                                    {popoverText}
                                </Typography>
                            </Popover>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                        }}
                        xs={12}
                        sm={9}
                        className={'customScrollbar'}
                    >
                        <Grid
                            direction={'row'}
                            container
                            className={'customScrollbar'}
                            style={{
                                height: '100%',
                                overflow: 'auto',
                                flexWrap: 'nowrap',
                            }}
                        >
                            {shiftCarousel}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                open={isOpenCancelShiftDialog}
                onClose={handleCancelShiftDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cancel Shift</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {cancelMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {remaining > 0 ? (
                        <div>
                            <Button
                                onClick={handleCancelShiftDialogClose}
                                color="primary"
                            >
                                No
                            </Button>
                            <Button
                                onClick={handleCancelShiftRequest}
                                color="primary"
                                autoFocus
                            >
                                Yes, cancel this shift request.
                            </Button>
                        </div>
                    ) : (
                        <Button
                            onClick={handleCancelShiftDialogClose}
                            color="primary"
                        >
                            OK
                        </Button>
                    )}
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
        </div>
    )
}
const getIsFullyBooked = (bookedRequestsCount, quantity) => {
    if (bookedRequestsCount === quantity) {
        return true
    } else {
        return false
    }
}
const ShiftCarousel = (props) => {
    let shiftCollection = props.shiftCollection
    let requests = props.requests
    let quantity = shiftCollection.quantity
    let items = []

    let bookedRequests = []
    let pendingRequests = []

    requests.forEach((request) => {
        if (request.isActive) {
            bookedRequests.push(request)
        } else {
            pendingRequests.push(request)
        }
    })
    //tmp = requests //requests.length = 1
    let isFullyBooked = getIsFullyBooked(bookedRequests.length, quantity)

    let cardWidth = 4
    let defaultW = 4
    //console.log('RECALIGNULATING card')
    //console.log(requests)
    bookedRequests.forEach((request) => {
        if (isFullyBooked) {
            console.log('fully booked')
            let w = Math.round(12 / bookedRequests.length)
            cardWidth = w > defaultW ? w : defaultW
        } else {
            cardWidth = defaultW
        }
        items.push(
            <PlacedAssociateCard
                width={cardWidth}
                associateRequest={request}
                shift={request.shift}
                shiftCollection={shiftCollection}
                key={request.id}
                isPastShift={props.isPastShift}
            />
        )
    })

    if (isFullyBooked) {
        return items
    }

    // don't show applied workers for old shifts
    if (!props.isPastShift) {
        pendingRequests.forEach((request, index) => {
            //console.log('type check')
            //console.log(facility.type)
            if (request.isCanceled) {
                return null
            }
            //console.log('nurse')
            //console.log(nurse)
            items.push(
                <AssociateCard
                    key={request.id}
                    associateRequest={request}
                    shiftCollection={shiftCollection}
                    isPastShift={props.isPastShift}
                />
            )
        })
    }
    // no nurses
    if (items.length === 0) {
        let noNurseText = 'Awaiting Responses From Nurses'
        let noNurseSubheader = '(please check back later)'
        if (props.isPastShift) {
            noNurseText = 'No nurses placed for shift'
            noNurseSubheader = ''
        }

        items.push(
            <Grid
                item={12}
                style={{ overflow: 'hidden', minWidth: '100%' }}
                key={9000000}
            >
                <Card
                    style={{
                        height: '99%',
                        textAlign: 'center',
                        margin: '1px',
                    }}
                >
                    <CardContent
                        style={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" component="h6">
                            {noNurseText}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {noNurseSubheader}
                            <br />
                            <br />
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return items
}

export default connect(mapStateToProps, mapDispatchToProps)(JobListing)
