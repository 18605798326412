import TzMoment from '../plugins/TzMoment'
const moment = require('moment')

const utils = {
    showTawk: () => {
        if (window.Tawk_API && window.Tawk_API.showWidget) {
            window.Tawk_API.showWidget()
        }
    },
    hideTawk: () => {
        if (window.Tawk_API && window.Tawk_API.hideWidget) {
            window.Tawk_API.hideWidget()
        }
    },
    loadChatUser: (user) => {
        if ((!user && !user.firstName) || !user.email) {
            return
        }
        let name = user.firstName + ' ' + user.lastName
        let email = user.email

        let obj = {
            name: name,
            email: email,
        }
        if (window.Tawk_API.onLoaded) {
            window.Tawk_API.setAttributes(obj)
        } else {
            window.Tawk_API.onLoad = function () {
                /*
            let key = '9ac25b74a7fa0a77316488f4f193cfc621a59e89'
            var hmac = crypto.createHmac('sha256', key)
            hmac.update(email)
            let hash = hmac.digest('hex')
            */
                // alert('onloadkj')
                window.Tawk_API.setAttributes(obj)
            }
        }
    },
    hasFacilities: (userObj) => {
        if (userObj && userObj.facilities && userObj.facilities.length > 0) {
            return true
        } else {
            return false
        }
    },
    getIsFullyBooked: (bookedRequestsCount, quantity) => {
        if (bookedRequestsCount === quantity) {
            return true
        } else {
            return false
        }
    },

    roundUpHalf: (num) => {
        let intPart = Math.trunc(num) // returns 3
        let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
        let roundedFloat = 0
        if (floatPart >= 0.5) {
            roundedFloat = 1
        } else if (floatPart > 0) {
            roundedFloat = 0.5
        }
        let total = intPart + roundedFloat
        total = total.toFixed(1)

        return total
    },

    roundHalf: (num) => {
        let intPart = Math.trunc(num) // returns 3
        let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
        let rounded = Math.round(floatPart * 2) / 2

        let total = intPart + rounded
        total = total.toFixed(1)

        return total
    },
    getDuration: (end, start) => {
        let hrs = moment.duration(moment(end).diff(moment(start))).asHours()
        // minimum billing hrs is 4 hrs
        let minimumHrs = 4
        if (hrs < minimumHrs) {
            hrs = minimumHrs
        }

        // subtract lunch rates
        if (hrs >= 5) {
            hrs = hrs - 0.5
        }

        hrs = utils.roundHalf(hrs)
        return hrs
    },
    prettyFormatDateTimeRange: (shift) => {
        let tzMoment = new TzMoment(shift.timezone)
        let shiftRange = shift.dateTimeRange
        let start = shiftRange[0]
        let end = shiftRange[1]
        shift.start = start
        shift.end = end
        shift.date = tzMoment.moment(start).format('MMMM Do, dddd')
        shift.startTime = tzMoment.moment(start).format('h:mma')
        shift.endTime = tzMoment.moment(end).format('h:mma')

        return shift
    },
}

export default utils
