import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux' //Code

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import Paper from '@mui/material/Paper'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import JobListing from '../JobListing/JobListing.js'
//import SortFacilityMenu from '../../SortFacilityMenu.js'
const moment = require('moment')

const mapStateToProps = (state) => ({ user: state.user })

const JobListings = (props) => {
    const reload = useCallback(() => {
        if (!props.user || !props.user.selectedFacility) {
            return null
        }
        let selectedFacility = props.user.selectedFacility

        let shiftCollections = selectedFacility.shiftCollections

        let displayShiftCollections = shiftCollections.map((x) => {
            //let y = Object.assign({}, x)
            let y = JSON.parse(JSON.stringify(x))
            return y
        })

        let currentShifts = []
        let historicShifts = []
        let currentItems = []
        let historicItems = []
        let items = []

        displayShiftCollections.forEach((x) => {
            if (moment(x.end).isAfter(new Date())) {
                currentShifts.push(x)
            } else {
                historicShifts.push(x)
            }
        })

        currentItems = currentShifts.map((shiftCollection, index) => {
            return (
                <JobListing
                    key={shiftCollection.id}
                    shiftCollection={shiftCollection}
                    isPastShift={false}
                />
            )
        })

        historicItems = historicShifts.map((shiftCollection, index) => {
            return (
                <JobListing
                    key={shiftCollection.id}
                    shiftCollection={shiftCollection}
                    isPastShift={true}
                />
            )
        })
        // show most recent passed shifts first
        historicItems.reverse()

        items.push(
            <div key="upcomingShiftsHeader">
                <h2>Current Requests</h2>
            </div>
        )
        if (currentItems.length === 0) {
            items.push(
                <Card variant="outlined">
                    <CardContent>
                        <span
                            eleveation={2}
                            square={false}
                            key="noUpcomingShifts"
                        >
                            No requests for nurses. Request a nurse.
                        </span>
                    </CardContent>
                </Card>
            )
        } else {
            items = items.concat(currentItems)
        }

        items.push(
            <div key="pastShiftsHeader">
                <br />
                <h2>Past Requests</h2>
            </div>
        )
        if (historicItems.length === 0) {
            items.push(
                <Card variant="outlined">
                    <CardContent>
                        <span eleveation={2} square={false} key="noPastShifts">
                            No past requests.
                        </span>
                    </CardContent>
                </Card>
            )
        } else {
            items = items.concat(historicItems)
        }

        return items
    }, [props.user])

    let tmp = reload()

    useEffect(() => {
        console.log('reloading listings')
        reload()
    }, [props.user.selectedFacility, reload])
    return tmp
}

export default connect(mapStateToProps, null)(JobListings)
