import React, { useState, useRef } from 'react'
import { connect } from 'react-redux' //Code

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
//import CallIcon from '@mui/icons-material/Call'

import { closeSnackbarMessage } from '../SnackbarMessage/snackbarMessageSlice'

const mapStateToProps = state => ({
    isOpen: state.snackbarMessage.isOpen,
    alertType: state.snackbarMessage.alertType,
    message: state.snackbarMessage.message,
    autoHideDuration: state.snackbarMessage.autoHideDuration
})
const mapDispatchToProps = dispatch => {
    return {
        closeSnackbar: () => {
            dispatch(closeSnackbarMessage())
        }
    }
}

const SnackbarMessage = props => {
    const handleAlertClose = (event, reason) => {
        //alert('close')
        props.closeSnackbar()
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            open={props.isOpen}
            onClose={handleAlertClose}
            autoHideDuration={props.autoHideDuration}
        >
            <Alert severity={props.alertType} onClose={handleAlertClose}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SnackbarMessage)
