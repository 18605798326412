import { createSlice } from '@reduxjs/toolkit'

export const snackbarMessageSlice = createSlice({
    name: 'snackbarMessage',
    initialState: {
        isOpen: false,
        message: '',
        alertType: 'success',
        autoHideDuration: 20000,
    },
    reducers: {
        openSnackbarMessage: (state, action, status) => {
            //window.location.hash = 'timesheet'

            state = {
                ...state,
                isOpen: true,
                message: action.payload.message ? action.payload.message : '',
                alertType: action.payload.alertType,
                autoHideDuration: action.payload.autoHideDuration
                    ? action.payload.autoHideDuration
                    : 20000,
            }

            return state
        },
        closeSnackbarMessage: (state, action, status) => {
            state = {
                ...state,
                isOpen: false,
            }
            return state
        },
    },
})

export const isOpen = (state) => state
export const { openSnackbarMessage, closeSnackbarMessage } =
    snackbarMessageSlice.actions

export default snackbarMessageSlice.reducer
