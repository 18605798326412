import { createSlice } from '@reduxjs/toolkit'

export const cancelShiftDialogSlice = createSlice({
    name: 'cancelShiftDialog',
    initialState: {
        isOpen: false,
        shiftCollection: {},
        shiftRequest: { user: {} }
    },
    reducers: {
        openCancelShiftDialog: (state, action) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            state = {
                ...state,
                isOpen: true,
                shiftRequest: action.payload.shiftRequest,
                shiftCollection: action.payload.shiftCollection
            }

            return state
        },
        closeCancelShiftDialog: (state, action) =>
            (state = { ...state, isOpen: false })
    }
})

export const isOpen = state => state
export const {
    openCancelShiftDialog,
    closeCancelShiftDialog
} = cancelShiftDialogSlice.actions

export default cancelShiftDialogSlice.reducer
