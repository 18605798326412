import React from 'react'

import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'

import TzMoment from '../plugins/TzMoment'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import { connect } from 'react-redux' //Code

import ShiftRequestButton from '../features/ShiftRequestButton/ShiftRequestButton'
import JobListing from '../features/JobListing/JobListing.js'
import * as dates from 'date-arithmetic'

import TimeGrid from 'react-big-calendar/lib/TimeGrid'

import Utils from '../helpers/utils.js'

import IconButton from '@mui/material/IconButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import RestoreIcon from '@mui/icons-material/Restore'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'

//const localizer = momentLocalizer(moment)

const CANCELED_GRAY = '#bbbbbb'
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    })

const gradient = {
    background:
        'repeating-linear-gradient( -55deg, #222, #222 10px, #333 10px, #333 10px)',
}

const mapStateToProps = (state) => ({ user: state.user })
const threeDayWeek = (props) => {
    const range = (date) => {
        let start = date
        let end = dates.add(start, 2, 'day')

        let current = start
        let range = []

        while (dates.lte(current, end, 'day')) {
            range.push(current)
            current = dates.add(current, 1, 'day')
        }

        return range
    }
    let newRange = range(props.date)
    /*
    this.navigate = (date, action) => {
        switch (action) {
            case Navigate.PREVIOUS:
                return dates.add(date, -3, 'day')

            case Navigate.NEXT:
                return dates.add(date, 3, 'day')

            default:
                return date
        }
    }
    */
    return <TimeGrid {...props} range={newRange} eventOffset={15} />
}
threeDayWeek.title = (date) => {
    return `${date.toLocaleDateString()} - ${dates
        .add(date, 3, 'day')
        .toLocaleDateString()}`
}
threeDayWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -3, 'day')

        case Navigate.NEXT:
            return dates.add(date, 3, 'day')

        default:
            return date
    }
}

const MyCalendar = (props) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [isOpenViewShift, setIsOpenViewShift] = React.useState(false)
    const [events, setEvents] = React.useState([])
    const [resourceMap, setResourceMap] = React.useState([])
    const [selectedShift, setSelectedShift] = React.useState({})

    // on page load scroll to top, fix buggy nav
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //let timezone = 'America/Los_Angeles'
    let timezone = props.user.selectedFacility.timezone
    if (!timezone) {
        console.log('NO TIMEZONE')
        timezone = 'America/Los_Angeles'
    }
    let tzMoment = new TzMoment(timezone)
    /*
    tzMoment.moment.locale('en', {
        week: {
            dow: 1,
            doy: 1
        }
    })
    */

    let localizer = momentLocalizer(tzMoment.moment)
    localizer.segmentOffset = 0

    const separateShiftsByStatus = (shiftCollection) => {
        let bookedRequests = []
        let pendingRequests = []
        shiftCollection.requests.forEach((request) => {
            if (request.isActive) {
                bookedRequests.push(request)
            } else {
                pendingRequests.push(request)
            }
        })
        shiftCollection.bookedRequests = bookedRequests
        shiftCollection.pendingRequests = pendingRequests
        return shiftCollection
    }
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const switchView = React.useCallback(
        (viewName, forceReRender) => {
            if (
                !props.user ||
                !props.user.selectedFacility ||
                !props.user.selectedFacility.id
            ) {
                console.log('returning now!')
                return
            }

            console.log('viewName ' + viewName)
            let selectedFacility = props.user.selectedFacility
            let shiftCollections = selectedFacility.shiftCollections

            let timezone = 'America/Los_Angeles'
            if (selectedFacility.shiftCollections.length > 0) {
                timezone = selectedFacility.shiftCollections[0].timezone
            }
            let tzMoment = new TzMoment(timezone)

            const tableShiftView = (viewName) => {
                let tmp = shiftCollections.map((x, i) => {
                    let y = Object.assign({}, x)
                    let start = tzMoment.moment(y.start)
                    let end = tzMoment.moment(y.end)
                    y.date = start.format('MMMM Do, dddd')
                    y.startTime = start.format('h:mm')
                    y.endTime = end.format('h:mma')
                    y.start = new Date(start)
                    y.allDay = false
                    y.end = new Date(end)
                    y.resourceId = y.type

                    y = separateShiftsByStatus(y)

                    let isFullyBooked = Utils.getIsFullyBooked(
                        y.bookedRequests.length,
                        y.quantity
                    )

                    y.isFilled = isFullyBooked
                    if (y.isCancelled) {
                        y.isFilled = false
                    }

                    y.title = `(${y.quantity}) ${y.type}: `

                    if (y.isCanceled || y.isNoShow) {
                        y.status = (
                            <span style={{ color: 'red' }}>
                                <i>Canceled</i>
                            </span>
                        )
                    } else if (y.booked_id) {
                        y.status = (
                            <span style={{ color: 'green' }}>Confirmed</span>
                        )
                    } else {
                        y.status = (
                            <span style={{ color: 'orange' }}>Pending</span>
                        )
                    }

                    if (y.isCanceled) {
                        y.title += '(CANCELED)'
                    } else if (y.isFilled) {
                        y.isFilled = true
                        console.log('testing')
                        console.log(y)
                        let names = getNamesForShift(y)
                        y.title += `Filled by ${names}`
                    } else if (y.bookedRequests.length > 0) {
                        let names = getNamesForShift(y)
                        y.title += `Partly filled by ${names} (${y.bookedRequests.length} of ${y.quantity} positions)`
                    } else {
                        y.title += '(Open)'
                    }

                    y.eventView = viewName
                    return y
                })
                return tmp
            }

            const calendarShiftView = (viewName) => {
                let tmp = shiftCollections.map((x, i) => {
                    let y = Object.assign({}, x)
                    let start = tzMoment.moment(y.start)
                    let end = tzMoment.moment(y.end)
                    y.date = start.format('MMMM Do, dddd')
                    y.startTime = start.format('h:mm')
                    y.endTime = end.format('h:mma')
                    y.start = new Date(start)
                    y.allDay = false
                    y.end = new Date(end)
                    y.title = `(${y.quantity}) ${y.type}: ${y.startTime} - ${y.endTime}` //console.log(start, y.date)
                    y.resourceId = y.type
                    y.eventView = viewName

                    y = separateShiftsByStatus(y)
                    let isFullyBooked = Utils.getIsFullyBooked(
                        y.bookedRequests.length,
                        y.quantity
                    )

                    y.isFilled = isFullyBooked
                    if (y.isCancelled) {
                        y.isFilled = false
                    }

                    if (y.isCanceled || y.isNoShow) {
                        y.title = <del> {y.title} </del>
                        y.status = (
                            <span style={{ color: 'red' }}>
                                <i>Canceled</i>
                            </span>
                        )
                    } else if (isFullyBooked) {
                        y.status = (
                            <span style={{ color: 'green' }}>Filled</span>
                        )
                    } else if (y.bookedRequests.length > 0) {
                        y.status = (
                            <span style={{ color: 'teal' }}>Partly Filled</span>
                        )
                    } else {
                        y.status = (
                            <span style={{ color: 'orange' }}>Not filled</span>
                        )
                    }

                    return y
                })
                return tmp
            }
            console.log('cal ref')
            if (!calendarRef && !calendarRef.current) {
                viewName = 'month'
                console.log('no cal ref, first load?')
            } else {
                console.log(calendarRef.current.props.view)
            }
            // set view name to month if not existant
            if (!viewName) {
                let selectedFacility = props.user.selectedFacility
                let shiftCollections = selectedFacility.shiftCollections
                if (shiftCollections.length > 0) {
                    viewName = shiftCollections[0].eventView
                }
                if (!viewName) {
                    viewName = 'month'
                }
            }
            let tmpEvents = []

            let tmpResourceMap = []
            if (viewName === 'work_week') {
                tmpResourceMap = null
            } else {
                tmpResourceMap = [
                    { resourceId: 'CNA', resourceTitle: 'CNA' },
                    { resourceId: 'LVN', resourceTitle: 'LVN' },
                    { resourceId: 'RN', resourceTitle: 'RN' },
                ]
            }
            setResourceMap(tmpResourceMap)

            console.log('view ' + viewName)
            /*
        //on view only rendered once, as opposed to drill down view
        let isViewChange = false 
        if (events.length > 0) {
            viewName !== events[0].eventView
                ? (isViewChange = true)
                : (isViewChange = false)
        }
        if (!forceReRender && !isViewChange) {
            return
        }
        */
            console.log('rerendering')

            if (viewName === 'month') {
                tmpEvents = calendarShiftView(viewName)
            } else {
                tmpEvents = tableShiftView(viewName)
            }
            console.log(tmpEvents.length)
            console.log(tmpEvents)
            setEvents(tmpEvents)
        },
        [props.user.selectedFacility]
    )

    const getNamesForShift = (shiftCollection) => {
        let names = []
        let requests = shiftCollection.requests
        requests.forEach((request) => {
            if (request.isActive) {
                names.push(request.user.firstName + ' ' + request.user.lastName)
            }
        })
        let namesStr = names.join('\n')

        return namesStr
    }
    React.useEffect(() => {
        if (
            !props.user ||
            !props.user.selectedFacility ||
            !props.user.selectedFacility.id
        ) {
            return
        }
        //setEvents(tmp)
        // REFRESH job listing view
        if (selectedShift.id) {
            let selectedFacility = props.user.selectedFacility
            let shiftCollections = selectedFacility.shiftCollections
            let shiftCollection = shiftCollections.find(
                (x) => x.id == selectedShift.id
            )
            //shift collection
            // reconnect it to store
            console.log('found shift collection ' + shiftCollection)
            setSelectedShift(shiftCollection)
        }
        //}, [props.user, selectedShift])
    }, [props.user, selectedShift])
    React.useEffect(() => {
        if (
            !props.user ||
            !props.user.selectedFacility ||
            !props.user.selectedFacility.id
        ) {
            return
        }
        switchView(null, true)
    }, [switchView])

    const calendarRef = React.useRef()
    const handleClose = () => {
        setIsOpen(false)
    }
    const onSelectEvent = (event) => {
        console.log('on select event')
        console.log(event)

        //shift collection
        setSelectedShift(event)
        setIsOpen(true)
    }
    //entire day cell color change

    const customDayPropGetter = (date) => {
        //let d = new Date()
        //let d = tzMoment.moment()
        let today = new Date()
        let todayDate = new Date().getDate()
        let facilityDate = tzMoment.moment(new Date()).format('D')
        if (todayDate != facilityDate) {
            today = new Date(tzMoment.moment(new Date()).subtract(1, 'days'))
            //alert('non matching ' + today)
        }
        let d = today

        d.setHours(0, 0, 0, 0)

        if (date < d) {
            return {
                className: 'past',
                style: {
                    //backgroundColor: 'rgb(230,230,230)',
                },
            }
            //backgroundColor: '#eaf6ff'
        } else return {}

        /*
        else if (date.toString() === d.toString()) {
            return {
                className: 'today',
                style: {
                    backgroundColor: '#eaf6ff',
                    //backgroundColor: '#FCF7CF'
                },
            }
        } else return {}
        */
    }
    const customSlotPropGetter = (date) => {
        /*
        if (date.getDate() === 7 || date.getDate() === 15)
            return {
                className: 'special-day',
                style: {
                    border:
                        'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa')
                }
            }
        else return {}
        */
        return {}
    }
    const eventPropGetter = (event) => {
        console.log('event prop getter ' + event.eventView)
        if (event.eventView !== 'agenda') {
            let obj = {}

            if (event.isCanceled) {
                obj.style = { backgroundColor: CANCELED_GRAY }
                obj.className = 'canceled'
            } else if (event.isFilled) {
                obj.style = { backgroundColor: 'purple' }
                obj.className = 'filled'
            } else if (event.bookedRequests.length > 0) {
                obj.style = { backgroundColor: 'teal' }
                obj.className = 'partlyFilled'
            } else {
                obj.style = { backgroundColor: 'orange' }
                obj.className = 'open'
            }
            return obj
        } else {
            return
        }
    }

    const renderCalendarViewButtons = (tProps) => {
        let viewNames = tProps.views
        const tView = tProps.view

        console.log('views')
        console.log(JSON.stringify(viewNames))
        if (viewNames.length > 1) {
            return viewNames.map((name) => (
                <button
                    type="button"
                    key={name}
                    className={{
                        'rbc-active': tView === name,
                    }}
                    onClick={() => tProps.onView(name)}
                >
                    {tProps.localizer.messages[name]}
                </button>
            ))
        }
    }

    const formats = React.useMemo(
        () => ({
            dateFormat: 'D',
        }),
        []
    )
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                padding: 0,
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? '2em' : '1em',
                height: !isDesktop
                    ? `calc(100vh - 3.5em - ${props.bottomSafeArea}px - ${props.topSafeArea}px )`
                    : 'auto',
                overflowY: !isDesktop ? 'scroll' : 'auto',
                fontSize: !isDesktop ? '0.9em' : '1em',
            }}
        >
            <div
                style={{
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    padding: '1em',
                }}
            >
                <ShiftRequestButton />
            </div>
            <Calendar
                ref={calendarRef}
                localizer={localizer}
                formats={formats}
                events={events}
                popup={true}
                tooltipAccessor={(event) => {
                    //console.log('toolip!' + JSON.stringify(event))
                    //
                    let msg = ''
                    if (event.isCanceled) {
                        msg = `Canceled: ${event.type}, ${event.startTime} - ${event.endTime}`
                    } else if (event.isFilled) {
                        let nurseNames = getNamesForShift(event)
                        if (nurseNames) {
                            msg = `(${event.quantity}x) ${event.type}: ${event.startTime} - ${event.endTime} \n${nurseNames} `
                        } else {
                            console.log(' ERROR no names')
                        }
                    } else if (event.bookedRequests.length > 0) {
                        let nurseNames = getNamesForShift(event)
                        if (nurseNames) {
                            msg = `(${event.quantity}x) ${event.type}: ${event.startTime} - ${event.endTime} \n${nurseNames} `
                        } else {
                            console.log(' ERROR no names')
                        }
                    } else {
                        msg = 'Not filled'
                    }
                    return msg
                }}
                onSelectEvent={onSelectEvent}
                views={
                    isDesktop
                        ? {
                              month: true,
                              day: true,
                              // doesn't support overlapping events well
                              //work_week: threeDayWeek,
                              agenda: true,
                          }
                        : {
                              month: true,
                              agenda: true,
                          }
                }
                step={30}
                eventPropGetter={eventPropGetter}
                slotPropGetter={customSlotPropGetter}
                components={{
                    toolbar: (tProps) => (
                        <div
                            className="rbc-toolbar"
                            style={!isDesktop ? { fontSize: '1.5em' } : null}
                        >
                            {/*
                            <span className="rbc-today-btn">
                                <IconButton
                                    component="span"
                                    onClick={() => {
                                        tProps.onNavigate('TODAY')
                                    }}
                                    size="large"
                                >
                                    <RestoreIcon />
                                </IconButton>
                            </span>
                            */}

                            <span
                                className="rbc-toolbar-label"
                                style={{
                                    width: !isDesktop ? '100%' : 'initial',
                                }}
                            >
                                <IconButton
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        tProps.onNavigate('PREV')
                                    }}
                                    size="large"
                                    style={{
                                        float: !isDesktop ? 'left' : 'clear',
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {tProps.label}
                                </span>
                                <IconButton
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        tProps.onNavigate('NEXT')
                                    }}
                                    size="large"
                                    style={{
                                        float: !isDesktop ? 'right' : 'clear',
                                    }}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </span>

                            {/*
                            <span className="rbc-btn-group">
                                {renderCalendarViewButtons(tProps)}
                            </span>
                            */}
                        </div>
                    ),
                    /*timeGutterHeader: () => <p>Custom gutter text</p>,*/
                    /*
                        event: event => (
                            <div
                                style={{
                                    borderColor: 'green',
                                    backgroundColor: 'blue'
                                }}
                            >
                                test
                                {event.event.resourceId}
                                {event.event.startTime}
                                {JSON.stringify(event)}
                            </div>
                        )
                        */
                    /*
                        eventWrapper: eventWrapperProps => {
                            const style = {
                                border: '4px solid',
                                borderColor:
                                    eventWrapperProps.event.start.getHours() %
                                    2 ===
                                    0
                                        ? 'green'
                                        : 'red',
                                padding: '5px',
                                backgroundColor: 'red'
                            }
                            console.log('event wrapper')
                            console.log(eventWrapperProps.children)
                            return (
                                <div
                                    style={style}
                                    userProps={{ className: 'testing' }}
                                    className="test"
                                >
                                    {eventWrapperProps.children}
                                </div>
                            )
                        }
                        */
                }}
                messages={{
                    agenda: 'Summary',
                    event: 'Nurses',
                    noEventsInRange: 'No nurses scheduled in this time period.',
                    work_week: '+3 Days',
                }}
                dayPropGetter={customDayPropGetter}
                startAccessor="start"
                endAccessor="end"
                showMultiDayTimes={true}
                style={isDesktop ? { height: 800 } : { height: '80%' }}
                onView={(view) => switchView(view)}
                resources={resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                dayLayoutAlgorithm="no-overlap"
            />{' '}
            {/*
                    getDrilldownView={(
                        targetDate,
                        currentViewName,
                        configuredViewNames
                    ) => switchView(currentViewName)}
                    */}
            <br />
            <br />
            <br />
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h4" component="h4">
                        <b>Calendar Legend</b>
                    </Typography>
                    <br />
                    <Typography variant="body2" component="div">
                        <div>
                            <div
                                style={{
                                    backgroundColor: 'orange',
                                    display: 'inline-block',
                                    marginRight: '1em',
                                    width: '8em',
                                    height: '2em',
                                    verticalAlign: 'middle',
                                }}
                            />
                            Open shift
                        </div>

                        <br />
                        <div>
                            <div
                                style={{
                                    backgroundColor: 'teal',
                                    display: 'inline-block',
                                    marginRight: '1em',
                                    width: '8em',
                                    height: '2em',
                                    verticalAlign: 'middle',
                                }}
                            />
                            Partly filled shift (has one or more open positions
                            for the timeslot)
                        </div>
                        <br />
                        <div>
                            <div
                                style={{
                                    backgroundColor: 'purple',
                                    display: 'inline-block',
                                    marginRight: '1em',
                                    width: '8em',
                                    height: '2em',
                                    verticalAlign: 'middle',
                                }}
                            />
                            Filled shift
                        </div>
                        <br />
                        <div>
                            <div
                                style={{
                                    backgroundColor: CANCELED_GRAY,
                                    display: 'inline-block',
                                    marginRight: '1em',
                                    width: '8em',
                                    height: '2em',
                                    verticalAlign: 'middle',
                                }}
                            />
                            Canceled shift
                        </div>
                    </Typography>
                </CardContent>
            </Card>
            {/* shift info, in future move to job */}
            <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {!moment(selectedShift.end).isAfter(new Date())
                        ? '(Past) '
                        : ''}
                    Shift Details
                </DialogTitle>
                <DialogContent>
                    <JobListing shiftCollection={selectedShift} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <br />
            <br />
        </Container>
    )
}
export default connect(mapStateToProps, null)(MyCalendar)
