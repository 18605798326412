// client facility
import React, { useEffect } from 'react'
import axios from 'axios'
import '@aws-amplify/ui-react/styles.css'
import {
    Heading,
    Authenticator,
    useTheme,
    useAuthenticator,
    CheckboxField,
    View,
    Image,
} from '@aws-amplify/ui-react'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import NavBar from './NavBar.js'
import BottomNav from './features/BottomNav/BottomNav.js'
import MyCalendar from './pages/Calendar'
import Settings from './pages/Settings'
import Menu from './pages/Menu'
import Onboarding from './pages/Onboarding'
import Invoices from './pages/Billing'

import Home from './pages/Home'

//import PlivoModal from './features/PlivoModal/PlivoModal'
import Timesheet from './features/Timesheet/Timesheet'
import SnackbarMessage from './features/SnackbarMessage/SnackbarMessage'
import Inbox from './pages/Inbox'
import { App as CapacitorApp } from '@capacitor/app'

import CancelShiftDialog from './features/CancelShiftDialog/CancelShiftDialog'
import ProfileDialog from './features/ProfileDialog/ProfileDialog'
import ReviewDialog from './features/ReviewDialog/ReviewDialog'
import ShiftRequest from './features/ShiftRequest/ShiftRequest'
import File from './features/File/File'
import ClockDialog from './features/ClockDialog/ClockDialog'
import MailModal from './features/MailModal/MailModal'

import { compose } from 'redux'
import { connect } from 'react-redux'

import Utils from './helpers/utils'

import { Provider, ErrorBoundary, RollbarContext } from '@rollbar/react' // <-- Provider imports 'rollbar' for us

import AnalyticsPageService from './services/analyticsPageService.js'
import { hotjar } from 'react-hotjar'

import { Capacitor } from '@capacitor/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    adaptV4Theme,
} from '@mui/material/styles'

import { SafeArea } from 'capacitor-plugin-safe-area'
//import { green, purple } from '@mui/material/colors'

const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                //main: purple[500],
                main: '#3f51b5',
            },
            secondary: {
                //main: green[500],
                main: '#008080',
            },
        },
    })
)

const nodeEnv = process.env.NODE_ENV
if (nodeEnv === 'production') {
    hotjar.initialize('2363540')
}
// same configuration you would create for the Rollbar.js SDK
const rollbarConfig = {
    enabled: nodeEnv === 'production',
    accessToken: '74b863b1bfa24c358357ac6f59ebf30b',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production',
    },
}

//.index.js.swo
//axios.defaults.baseURL = 'https://app-api.nurselab.co'

axios.defaults.baseURL = Capacitor.isNativePlatform()
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_API_URL
const mapStateToProps = (state) => ({ user: state.user })

// set up back button behavior, only for android
CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
        CapacitorApp.exitApp()
    } else {
        window.history.back()
    }
})

const App = (props) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    const [bottomSafeArea, setBottomSafeArea] = React.useState(0)
    const [topSafeArea, setTopSafeArea] = React.useState(0)

    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            // only calculate safe areas for ios. not needed for android 10...
            if (Capacitor.getPlatform() !== 'ios') {
                return
            }
            setBottomSafeArea(insets.bottom)
            setTopSafeArea(insets.top)
            console.log('insets ' + insets)
        })
    }, [SafeArea])

    // code to run on component mount
    useEffect(() => {
        // don't show on mobile
        if (!isDesktop) {
            return
        }

        /*<script type="text/javascript">*/
        //var Tawk_API = window.Tawk_API || {}
        if (!window.Tawk_API) {
            window.Tawk_API = {}

            window.Tawk_API.customStyle = {
                visibility: {
                    mobile: {
                        yOffset: '75px',
                    },
                },
            }
        }
        /*
        if (name) {
            let key = '9ac25b74a7fa0a77316488f4f193cfc621a59e89'
            var hmac = crypto.createHmac('sha256', key)

            hmac.update(email)
            name = 'Scott Beta'
            email = 'beta@nurselab.co'
            let hash = hmac.digest('hex')
            setTimeout(function() {
                alert('updated')
                console.log('api')
                console.log(Tawk_API)
                console.log(JSON.stringify(Tawk_API))
                window.Tawk_API.visitor = {
                    name: name,
                    email: email
                }
            }, 120000)
            //alert(hash)
        }
        */

        //var Tawk_LoadStart = new Date()
        ;(function () {
            var s1 = document.createElement('script'),
                s0 = document.getElementsByTagName('script')[0]
            s1.async = true
            s1.src = 'https://embed.tawk.to/604534cc385de407571d8920/1f075cfgh'
            s1.charset = 'UTF-8'
            s1.setAttribute('crossorigin', '*')

            s0.parentNode.insertBefore(s1, s0)
        })()

        window.Tawk_API.onLoad = function () {
            window.Tawk_API.hideWidget()
        }

        console.log('loadchat userk')
        console.log(Utils)
        Utils.loadChatUser(props.user)
        /*<!--End of Tawk.to Script-->*/
    }, [isDesktop])

    const authenticatorFormFields = {
        signUp: {
            given_name: {
                placeholder: 'First Name',
                isRequired: true,
                label: 'First Name:',
                labelHidden: true,
                order: 1,
            },
            family_name: {
                placeholder: 'Last Name',
                isRequired: true,
                labelHidden: true,
                label: 'Last Name:',
                order: 2,
            },
            password: {
                placeholder: 'Password',
                isRequired: true,
                labelHidden: true,
                label: 'Password:',
            },
            confirm_password: {
                placeholder: 'Confirm Password',
                isRequired: true,
                labelHidden: true,
                label: 'Confirm Password:',
            },
            email: {
                placeholder: 'Email',
                isRequired: true,
                labelHidden: true,
                label: 'Email:',
            },
            phone_number: {
                placeholder: 'Phone Number',
                labelHidden: true,
                isRequired: true,
            },
        },
        signIn: {
            username: {
                placeholder: 'Email',
                isRequired: true,
                labelHidden: true,
                label: 'Email:',
            },
            password: {
                placeholder: 'Password',
                isRequired: true,
                labelHidden: true,
                label: 'Password:',
            },
        },
    }
    const authenticatorComponents = {
        Header() {
            const { tokens } = useTheme()

            if (isDesktop) {
                return (
                    <View
                        textAlign="left"
                        padding={`${tokens.space.large}  0  ${tokens.space.medium} 0`}
                        style={{ minWidth: '95vw' }}
                    >
                        <Image
                            alt="NurseLab logo"
                            src="/nurselab.png"
                            style={{ height: '5.5em', marginBottom: '0.5em' }}
                        />
                        <div
                            padding={`0 0  0 0`}
                            style={{ fontSize: '1.25em' }}
                        >
                            Welcome
                        </div>
                    </View>
                )
            } else {
                return (
                    <>
                        <View
                            textAlign="left"
                            padding={`${tokens.space.small}  0  ${tokens.space.small} 0`}
                            style={{ minWidth: '95vw' }}
                        >
                            <Image
                                alt="NurseLab logo"
                                src="/nurselab.png"
                                style={{
                                    height: '2.5em',
                                    marginBottom: '0',
                                }}
                            />
                            <div
                                padding={`0 0  0 0`}
                                style={{ fontSize: '1.25em' }}
                            >
                                Welcome
                            </div>
                        </View>
                    </>
                )
            }
        },
        Footer() {
            // Return today's date and time
            let currentTime = new Date()
            let year = currentTime.getFullYear()

            return (
                <div
                    style={{
                        marginTop: '2em',
                        fontSize: '0.6em',
                        marginLeft: '0',
                        marginBottom: '1em',
                    }}
                >
                    All Rights Reserved, NurseCall Inc. DBA NurseLab ©️{year}.
                    <div
                        style={{
                            height:
                                // for scroll
                                Capacitor.getPlatform() === 'ios'
                                    ? '60vh'
                                    : '0',
                        }}
                    ></div>
                </div>
            )
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme()

                if (isDesktop) {
                    return (
                        <Heading
                            padding={`${tokens.space.small} 0 0 ${tokens.space.small}`}
                            level={6}
                        >
                            Facility Sign-in
                        </Heading>
                    )
                } else {
                    return <></>
                }
            },
        },
        SignUp: {
            Header() {
                const { tokens } = useTheme()

                if (isDesktop) {
                    return (
                        <Heading
                            padding={`${tokens.space.small} 0 0 ${tokens.space.small}`}
                            level={6}
                        >
                            Facility Sign-up
                        </Heading>
                    )
                } else {
                    return <></>
                }
            },
            FormFields() {
                const { validationErrors } = useAuthenticator()

                return (
                    <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />
                        {/* Append & require Terms & Conditions field to sign up  */}
                        {/*hasError={!!validationErrors.acknowledgement}*/}
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            required="true"
                            label={
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '0.35em',
                                    }}
                                >
                                    By signing up, I agree with the{' '}
                                    <a
                                        href="https://nurselab.co/terms"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        terms and conditions
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://nurselab.co/facility-agreement"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        facility agreement
                                    </a>
                                    .
                                </span>
                            }
                        />
                    </>
                )
            },
        },
    }

    return (
        <Provider config={rollbarConfig}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary>
                        {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
                        <div
                            className="App"
                            style={{
                                paddingTop: topSafeArea + 'px',
                                backgroundColor: 'white',
                            }}
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    zIndex: 2,
                                    top: 0,
                                    height: topSafeArea + 'px',
                                }}
                            ></div>
                            <Authenticator
                                style={{
                                    // ios functionality
                                    overflowY: !isDesktop ? 'scroll' : 'auto',
                                    overflowX: !isDesktop ? 'hidden' : 'auto',
                                    height: !isDesktop
                                        ? `calc(100vh - ${topSafeArea}px - ${bottomSafeArea}px)`
                                        : 'auto',
                                }}
                                loginMechanisms={['email']}
                                className="amplifySignIn"
                                usernameAlias="email"
                                components={authenticatorComponents}
                                formFields={authenticatorFormFields}
                                signUpAttributes={[
                                    'given_name',
                                    'family_name',
                                    'phone_number',
                                ]}
                                services={{
                                    async validateCustomSignUp(formData) {
                                        if (!formData.acknowledgement) {
                                            return {
                                                acknowledgement:
                                                    'You must agree to the terms and conditions and privacy policy.',
                                            }
                                        }
                                    },
                                }}
                            >
                                <Router>
                                    {/* main view port for content on desktop/mobile */}
                                    <div
                                        style={
                                            isDesktop
                                                ? {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: '0',
                                                  }
                                                : {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: '0',
                                                  }
                                        }
                                    >
                                        <NavBar
                                            topSafeArea={topSafeArea + 'px'}
                                        />

                                        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
                                        <Switch>
                                            <Route exact path="/">
                                                <RollbarContext context="/">
                                                    <Home
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/onboarding">
                                                <RollbarContext context="onboarding">
                                                    <Onboarding
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/calendar">
                                                <RollbarContext context="calendar">
                                                    <MyCalendar
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/inbox">
                                                <RollbarContext context="inbox">
                                                    <Inbox
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/invoices">
                                                <RollbarContext context="invoices">
                                                    <Invoices
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/settings">
                                                <RollbarContext context="settings">
                                                    <Settings
                                                        bottomSafeArea={
                                                            bottomSafeArea
                                                        }
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/menu">
                                                <RollbarContext context="menu">
                                                    <Menu
                                                        topSafeArea={
                                                            topSafeArea
                                                        }
                                                    />
                                                </RollbarContext>
                                            </Route>
                                        </Switch>
                                        {/*
                                        <PlivoModal
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        */}
                                        <CancelShiftDialog
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <ProfileDialog
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <ReviewDialog
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <ClockDialog
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <Timesheet
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <SnackbarMessage />
                                        <ShiftRequest
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <MailModal
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                        <File
                                            bottomSafeArea={bottomSafeArea}
                                            topSafeArea={topSafeArea}
                                        />
                                    </div>
                                    <BottomNav
                                        bottomSafeArea={bottomSafeArea}
                                        topSafeArea={topSafeArea}
                                    />
                                    <AnalyticsPageService />
                                    {/*spaces ok below pages, not ok after amplify sign
                            in pages*/}
                                </Router>
                            </Authenticator>
                        </div>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    )
}

/*
export default withAuthenticator(App, {
    usernameAttributes: 'email'
})

*/

export default compose(connect(mapStateToProps, null))(App)
